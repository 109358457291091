import React, { useState, useEffect, useContext } from 'react'
import Application from '../application';
import { Container, Button , Loader} from 'semantic-ui-react';

import * as api from '../api';
import './index.css'
import TextBlock from './textblock';
import TextBlockArabic from './textblock-ar';
import { CTX } from '../state/store';
import { useLocation } from "react-router-dom";
import { message_types } from '../state/constants';

const uuidv4 = require('uuid/v4');

export default () => {

  const [isAppVisible, setIsAppVisible] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [refresh, setRefresh] = useState('');
  const [appState, doAction] = useContext(CTX);
  const [loading, setLoading] = useState(true);


  let location = useLocation();

  useEffect(() => {
    const page = location.pathname.substr(1);
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])



  useEffect(() => {
    setLoading(true);
    if (appState.user) {
      api.getCandidate(appState.user).then(c => {
        if (c) {
          setCandidate(c);
        }
        setLoading(false)
      }).catch(setLoading(false))

    } else {
      setLoading(false);
    }
     
    // eslint-disable-next-line
  }, [refresh]);


   useEffect(() => {
     setLoading(true);
    if (appState.user) {
      api.getCandidate(appState.user).then(c => {
        setCandidate(c);
        setLoading(false);
      }).catch((err) =>  {
        setLoading(false);
  
      })

    } else {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [appState.user]);


  async function revokeApplication() {
    setLoading(true)
    const result = await api.deleteCandidate(candidate.id);
    if (result)
      setCandidate(null);

    const objects = await api.listObjects('', 'protected', await api.getIdentityId())

    const promises =  objects.map(async(object)=> {
      await api.removeObject(object.key, 'protected');
    })
    await Promise.all(promises);

    setLoading(false);

  }

  function onSubmit() {
    setLoading(true);
    const random = uuidv4();
    setRefresh(random);
    setLoading(false);
  }

  function onCancel() {
    setIsAppVisible(false);
  }


  function continueApplication () {
    setIsAppVisible(true)
  }



  return (
    <Container>
      {!loading? 
        <>
          {candidate && candidate.status !== "SAVED"  && candidate.status !== "NEEDS_CORRECTING" &&

            <div className="apply-container">
              <h2>{labels[appState.language].applicationSubmitted}</h2>
              <Button secondary onClick={revokeApplication}>{labels[appState.language].revokeApplication}</Button>
            </div>
          }

          {candidate && (candidate.status === "SAVED") && !isAppVisible   &&

          <div className="apply-container">
            <h2>{labels[appState.language].applicationSaved}</h2>
            <Button secondary onClick={continueApplication}>{labels[appState.language].continueApp}</Button>
            <br/>
            <br/>
            <Button secondary onClick={revokeApplication}>{labels[appState.language].revokeApplication}</Button>
          </div>
          }

        

          {!isAppVisible && !candidate &&
            <div className="apply-container">


              {appState.language === "en" &&
                <>
                  <TextBlock />
                  {appState.language === "en" && <h2>Democracy starts here.</h2>}
                  <Button color="teal" onClick={() => setIsAppVisible(true)}>Become a candidate</Button>
                </>
              }
              {appState.language === "ar" &&
                <>
                  <TextBlockArabic />

                  <h2>الديموقراطية تبدأ هنا</h2>

                  <Button color="teal" onClick={() => setIsAppVisible(true)}>أصبح مرشحاً</Button>
                </>
              }
            </div>
          }

          { ( (isAppVisible && !candidate) || (candidate && (candidate.status === "SAVED" || candidate.status === "NEEDS_CORRECTING") && isAppVisible) ) &&
          <Application candidate={candidate} onSubmit={onSubmit} onCancel={onCancel} />
          }

        </>

      :

        <Loader active/>

      }

    </Container>
  )
}


const labels = {
  en: {
    applicationSaved: "Your application has been saved.",
    continueApp: "Continue Application",
    applicationSubmitted: "Your application has been submitted. We'll be in touch.",
    revokeApplication: "Revoke Application"

  },
  ar: {
    applicationSaved: "لقد تم حفظ الطلب",
    continueApp: "أكمل الطلب",
    applicationSubmitted: "تمّ  ارسال طلبك",
    revokeApplication: "إلغاء الطلب"
  }
}