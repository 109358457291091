export const message_types = {
  SET_USER: 'set_user',
  SET_SELECT_PAGE: 'set_selected_page',
  SET_LANGUAGE: 'set_language'
}

export const pages = {
  HOME: 'home',
  APPLY: 'apply',
  ABOUT: 'about',
  CANDIDATES: 'candidates',
  CANDIDATE: 'candidate',
  INTERVIEWS: 'interviews',
  ACCOUNT: 'account',
  ADMIN: 'admin',
  ERROR: 'error'
}
