import React from 'react'
import './index.css'

export default () => {

  return (
    <div className="apply-textblock-en-content">
      <p>{p1}</p>
      <p>{p2}</p>
      <p>{p3}</p>
      <p>{p4}</p>
      <p>{p5}</p>
      <p>{p6}</p>
      <p>{p7}</p>
      <p>{p8}</p>
      <p>{p9} <a href="/account">click here</a></p>
      <p>{p10}</p>
      <p>{p11}</p>
    </div>
  )
}

const p1 =
  "If you are here, you believe you are the most suitable person to \n" +
  "be a part of Lebanon’s next generation of representation to serve our \n" +
  "country. Welcome."

const p2 =
  "A few things for you to know before you decide to proceed with \n" +
  "the process."

const p3 =
  "This platform is not here to help you, it is here to serve the \n" +
  "Lebanese people. Do not apply if you are not up to the \n" +
  "opportunity."

const p4 =
  "Applying is only the first step, following your application The \n" +
  "Initiative will be in touch to confirm we have received everything \n" +
  "in good order and will follow up if there are any errors."

const p5 =
  "We will then begin preparing a presentation to the Lebanese \n" +
  "people, in coordination with you, based on your submission. Be \n" +
  "aware that your submission is the basis for both your interview \n" +
  "and your presentation to be voted for on the platform."

const p6 =
  "Once your candidacy is featured in the Candidates section of this \n" +
  "website, a score will be given out of 100 based on what you have \n" +
  "submitted (the breakdown of the score will be provided in the \n" +
  "submission section of this page). This score will determine your \n" +
  "placement on the page with the higher score placing you at the \n" +
  "top. The higher the score the more transparent the candidate."

const p7 =
  "Once voting begins whether you will be on this platform or not is \n" +
  "out of our hands, it depends solely on the will of the people. If \n" +
  "you are qualified, we have no doubt the people will choose you."

const p8 =
  "Understand that this platform affords you the benefit of priceless \n" +
  "exposure, free of charge. You will be given the opportunity to \n" +
  "appeal to the greatest generation of Lebanese citizens that ever \n" +
  "existed, and they will be just as quick to condemn as they are to \n" +
  "champion you."

const p9 =
  "You must meet the minimum submission requirements in order to \n" +
  "be able to apply, you must also be logged in, if you haven’t \n" +
  "created an account yet, or if you have but aren’t logged in, "

const p10 =
  "Once logged in you can save your progress before you submit \n" +
  "and finish everything in as much time as you need."

const p11 =
  "We look forward to discovering that our serving the people of \n" +
  "Lebanon works in your favor and that you gain the vote of some \n" +
  "of the greatest people on earth."
