// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "theinitiative-20191215172253-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1a220c7l125fu.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:6f7867e6-ab2e-43cf-90e2-3ab046284eae",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_5hNmcxsSw",
    "aws_user_pools_web_client_id": "7rbl8sa7coc72pvu541o9hbnk9",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://liugwu5tkveshmjjxe7foj7ao4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "theinitiative6098fca685d64fb48b29c4273810e3a0172142-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
