import React, { useState, useEffect, useContext } from 'react'

import { Button, Container, Form, Radio, Divider,  Image } from 'semantic-ui-react'

import { SignIn, Authenticator, SignUp, Greetings } from 'aws-amplify-react';

import * as api from '../api';
import './index.css'
import { CTX } from '../state/store';
import { message_types } from '../state/constants';
import { pages } from '../state/constants';
import labels from './constants';
import CustomSignIn from '../account/customSignIn';
import CustomSignUp from '../account/customSignUp';
import { useLocation, useHistory } from 'react-router-dom';


const options = [
 { key: 'SUBMITTED', text: 'Application Submitted', value: 'SUBMITTED' },
 { key: 'UNDER_REVIEW', text: 'Application Under Review', value: 'UNDER_REVIEW' },
 { key: 'NEEDS_CORRECTING', text: 'Application Needs Correcting', value: 'NEEDS_CORRECTING' },
 { key: 'ACCEPTED', text: 'Application Accepted', value: 'ACCEPTED' },
 { key: 'PUBLISHED', text: 'Application Published', value: 'PUBLISHED' },
]




const Application = (props) => {

  const defaultCandidate = {
    id: '',

    firstName: '',
    lastName: '',
    preferredEmail: '',
    preferredPhoneNumber: '',
    dateOfBirth: '',

    status: '',

    owner: '',

    subjectedToDiscrimination: '',
    subjectedToDiscriminationDescription: '',

    subjectedAnyoneToDiscrimination: '',
    subjectedAnyoneToDiscriminationDescription: '',

    accusedOfCrime: '',
    accusedOfCrimeDescription: '',

    convictedOfCrime: '',
    convictedOfCrimeDescription: '',

    violatedAnyLaw: '',
    violatedAnyLawDescription: '',

    subjectOfPublicScandal: '',
    subjectOfPublicScandalDescription: '',

    otherCitizenship: '',
    otherCitizenshipDescription: '',

    candidateForPublicPosition: '',
    candidateForPublicPositionDescription: '',

    activeMemberInPoliticalParty: '',
    activeMemberInPoliticalPartyDescription: '',

    affiliationWithPoliticalParty: '',
    affiliationWithPoliticalPartyDescription: '',

    donatedToCampaigns: '',
    donatedToCampaignsDescription: '',

    involvedInBankruptcy: '',
    involvedInBankruptcyDescription: '',

    accussedOfTaxEvasion: '',
    accussedOfTaxEvasionDescription: '',

    audited: '',
    auditedDescription: '',

    involvedInGovernmentProject: '',
    involvedInGovernmentProjectDescription: '',

    involvedInNonGovernmentProject: '',
    involvedInNonGovernmentProjectDescription: '',

    appointedAsConsultantByGovernment: '',
    appointedAsConsultantByGovernmentDescription: '',

    fundedByNonGovernment: '',
    fundedByNonGovernmentDescription: '',

    fundedByGovernment: '',
    fundedByGovernmentDescription: '',

    bankAccountOutsideLebanon: '',
    bankAccountOutsideLebanonDescription: '',

    stripYourselfFromPoliticalImmunity: '',
    stripYourselfFromPoliticalImmunityDescription: '',

    removeYourselfFromConflictOfInterest: '',
    removeYourselfFromConflictOfInterestDescription: '',


    // where you stand
    onSeparationOfReligionAndState: '',
    onSeparationOfReligionAndStateDescription: '',

    onLegalizingCivilMarriage: '',
    onLegalizingCivilMarriageDescription: '',

    onLegalizingMarijuana: '',
    onLegalizingMarijuanaDescription: '',

    onLGBTQ: '',
    onLGBTQDescription: '',

    onLegalizingAbortion: '',
    onLegalizingAbortionDescription: '',

    onUnifyingPersonalStatusLaws: '',
    onUnifyingPersonalStatusLawsDescription: '',

    onUnifyingInheritanceLaws: '',
    onUnifyingInheritanceLawsDescription: '',

    onProstitution: '',
    onProstitutionDescription: '',

    onFreedomOfPress: '',
    onFreedomOfPressDescription: '',

    onFreedomOfSpeech: '',
    onFreedomOfSpeechDescription: '',

    onIncorporationOfTechnology: '',
    onIncorporationOfTechnologyDescription: '',

    onWomenRights: '',
    onWomenRightsDescription: '',

    onChildrenRights: '',
    onChildrenRightsDescription: '',

    onLebaneseMothers: '',
    onLebaneseMothersDescription: '',

    onStatelessPeople: '',
    onStatelessPeopleDescription: '',

    onPalestinianRefugees: '',
    onPalestinianRefugeesDescription: '',

    onGrantingSyriansRefugeeStatus: '',
    onGrantingSyriansRefugeeStatusDescription: '',

    onGovernmentLootedFunds: '',
    onGovernmentLootedFundsDescription: '',

    onEnvironment: '',
    onEnvironmentDescription: '',

    onMeritocracy: '',
    onMeritocracyDescription: '',

    onTheConstitution: '',
    onTheConstitutionDescription: '',

    onElectingPresidentByThePeople: '',
    onElectingPresidentByThePeopleDescription: '',

    onPeggingLiraToUsd: '',
    onPeggingLiraToUsdDescription: '',

    onLebaneseDiaspora: '',
    onLebaneseDiasporaDescription: '',

    onFemaleQuota: '',
    onFemaleQuotaDescription: '',

    onNewElectoralLaw: '',
    onNewElectoralLawDescription: '',

    onEarlyParliamentaryElections: '',
    onEarlyParliamentaryElectionsDescription: '',

    onTheLebaneseRevolution: '',
    onTheLebaneseRevolutionDescription: '',

    onLebnonAndSyria: '',
    onLebnonAndSyriaDescription: '',

    roleModel: '',
    identityId: '',

    adminComment: ''
  }

  const [candidate, setCandidate] = useState(defaultCandidate);
  const [isStatusButtonLoading, setStatusButton] = useState(false)
  const [profilePictureURL , setProfilePictureURL] = useState()
  const [appState,] = useContext(CTX);
  let location = useLocation();
  let history = useHistory();
  const [filesName, setFilesName] = useState({})

  useEffect(() => {

    // get the location => '/candidate/123', split into an array
    const pathSections = location.pathname.split('/');
    // get the last item of the array which is the candidate id
    const candidateId = pathSections[pathSections.length - 1];


    if (candidateId) {
      api.getCandidate(candidateId).then( async (res) => {
        if (res) {
          setCandidate(res)
        } else {
          history.push("/" + pages.ERROR)
        }
      })
    }
    // eslint-disable-next-line
  }, []);



  useEffect(()=> {
    async function getProfilePicture () {
      var uri = await api.getObject('profilePicture', 'protected', { identityId: candidate.identityId })
      setProfilePictureURL(uri)           
    }
    getProfilePicture()
  },[candidate])



  async function onSubmitStatusChange () {
    setStatusButton(true)

    try {
      await api.updateCandidate({owner: candidate.owner, status: candidate.status, id: candidate.id})
    } catch(err) {
    }
    setStatusButton(false)
    
  }



  function onStatusChange (e , {value}) {
    setCandidate({...candidate, status: value})
  }

  async function listCandiateS3Objects(userId) {

    console.log('id' , candidate.identityId)
    var filesList =  await api.listObjects('', 'protected', userId)
    var filesNamesMap = {}
    await filesList.forEach((element)=> {
      filesName[element.key.split('.')[0]] = element.key

    })
    await setFilesName(filesNamesMap)
    console.log(filesName)
  }


  



  return (
    <Container>
      <div className="candidate-container">


        <div className={"candidate-inner-" + appState.language}>
          <Form>

            <div className="candidate-labels-container">

              <div>
                <label basic>Username</label> <h4>{candidate.id}</h4>
                <Divider />
               
              </div>
               <div>

                <Image src={profilePictureURL} rounded size='small'/>

  

              </div>

              <div>
                <label basic>Email</label><h4>{candidate.preferredEmail}</h4>
                <Divider />
                
              </div>
              </div>

              <div className="candidate-labels-container">
              <div>
                <label basic>Phone</label> <h4>{candidate.preferredPhoneNumber}</h4>
                <Divider />
                
              </div>

                <div>
                <label basic>Name</label><h4>{candidate.firstName} {candidate.lastName}</h4>
                <Divider />
                
              </div>

              <div>
                <label basic>Date Of Birth</label> <h4>{candidate.dateOfBirth}</h4>
                <Divider />  
              </div>

            </div>


             <div className="candidate-status">
              <Form.Select
                fluid
                label='Status'
                options={options}
                placeholder='Status'
                onChange={onStatusChange}
                value={candidate.status}
              />
              <div>
                <Button disabled={isStatusButtonLoading} size="small" basic color="teal" onClick={onSubmitStatusChange}>
                  Submit Status
                </Button>
              </div>
              <br/>
            </div>

           
      
            <Divider />

        
    
              <Form.Field required>

                <label> {labels[appState.language].runFor }</label>
            
                <Radio
                  disabled
                  label={labels[appState.language].parliament }
                  name='radioGroup'
                  className="radio"
                  value='Parliament'
                  checked={candidate.runFor === 'Parliament'}
              
                />
                <Radio
                  disabled
                  label={labels[appState.language].minister }
                  name='runAs'
                  className="radio"
                  value='Minister'
                  checked={candidate.runFor === 'Minister'}
                />
              </Form.Field>

              <Form.Field required className="empty-form form-field">
                { candidate.runFor === "Minister"?
                <>
                  <label> {labels[appState.language].runForDescription }</label>
                  <div> {candidate["runForDescription"]}</div>

                </>

                :null

                }

              </Form.Field>


            <h2>{labels[appState.language].documents}</h2>
            <Divider />


            <Divider />
            <Form.Field required className="form-field">
              <label>{labels[appState.language].d1}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('whyvote', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>


            </Form.Field>



            <Form.Field required className="form-field">
              <label>{labels[appState.language].d2}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('criminalrecord', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>


            </Form.Field>



            <Form.Field required className="form-field">
              <label>{labels[appState.language].d3}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('pledge', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>

            </Form.Field>


            <Form.Field className="form-field">
              <label>{labels[appState.language].d4}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('taxreturns', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>

            </Form.Field>


            <Form.Field className="form-field">
              <label>{labels[appState.language].d5}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('commercialstatement', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>

            </Form.Field>


            <Form.Field className="form-field">
              <label>{labels[appState.language].d6}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('denialofownership', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>

            </Form.Field>


            <Form.Field className="form-field">
              <label>{labels[appState.language].d7}</label>

              <Button size="small" basic color="teal" onClick={() => {
                api.getObject('vehiclecertificate', 'protected', { identityId: candidate.identityId }).then(res => {
                  window.open(res, "_blank")
                })
              }}>Download</Button>


            </Form.Field>





            <h2>{labels[appState.language].questionnaire}</h2>
            <Divider />
            <p>{labels[appState.language].qintro}</p>



            <Form.Field required className="form-field">

              <label>{labels[appState.language].q1}</label>
              <Radio
                disabled={true}
                id="subjectedToDiscriminationYes"
                className="radio"
                label={labels[appState.language].yes}
                name='subjectedToDiscrimination'
                value="yes"
                checked={candidate['subjectedToDiscrimination'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="subjectedToDiscriminationNo"
                className="radio"
                label={labels[appState.language].no}
                name='subjectedToDiscrimination'
                value="no"
                checked={candidate['subjectedToDiscrimination'] === 'no'}

              />

              <div>{candidate.subjectedToDiscriminationDescription}</div>

            </Form.Field>




            <Form.Field required className="form-field">

              <label>{labels[appState.language].q2}</label>
              <Radio
                disabled={true}
                id="subjectedAnyoneToDiscriminationYes"
                className="radio"
                label={labels[appState.language].yes}
                name='subjectedAnyoneToDiscrimination'
                value="yes"
                checked={candidate['subjectedAnyoneToDiscrimination'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="subjectedAnyoneToDiscriminationNo"
                className="radio"
                label={labels[appState.language].no}
                name='subjectedAnyoneToDiscrimination'
                value="no"
                checked={candidate['subjectedAnyoneToDiscrimination'] === 'no'}

              />

              <div>{candidate.subjectedAnyoneToDiscriminationDescription}</div>

            </Form.Field>




            <Form.Field required className="form-field">

              <label>{labels[appState.language].q3}</label>
              <Radio
                disabled={true}
                id="accusedOfCrimeYes"
                className="radio"
                label={labels[appState.language].yes}
                name='accusedOfCrime'
                value="yes"
                checked={candidate['accusedOfCrime'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="accusedOfCrimeNo"
                className="radio"
                label={labels[appState.language].no}
                name='accusedOfCrime'
                value="no"
                checked={candidate['accusedOfCrime'] === 'no'}

              />

              <div>{candidate.accusedOfCrimeDescription}</div>

            </Form.Field>



            <Form.Field required className="form-field">

              <label>{labels[appState.language].q4}</label>
              <Radio
                disabled={true}
                id="convictedOfCrimeYes"
                className="radio"
                label={labels[appState.language].yes}
                name='convictedOfCrime'
                value="yes"
                checked={candidate['convictedOfCrime'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="convictedOfCrimeNo"
                className="radio"
                label={labels[appState.language].no}
                name='convictedOfCrime'
                value="no"
                checked={candidate['convictedOfCrime'] === 'no'}

              />

              <div>{candidate.convictedOfCrimeDescription}</div>

            </Form.Field>


            <Form.Field required className="form-field">

              <label>{labels[appState.language].q5}</label>
              <Radio
                disabled={true}
                id="violatedAnyLawYes"
                className="radio"
                label={labels[appState.language].yes}
                name='violatedAnyLaw'
                value="yes"
                checked={candidate['violatedAnyLaw'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="violatedAnyLawNo"
                className="radio"
                label={labels[appState.language].no}
                name='violatedAnyLaw'
                value="no"
                checked={candidate['violatedAnyLaw'] === 'no'}

              />

              <div>{candidate.violatedAnyLawDescription}</div>

            </Form.Field>


            <Form.Field required className="form-field">

              <label>{labels[appState.language].q6}</label>
              <Radio
                disabled={true}
                id="subjectOfPublicScandalYes"
                className="radio"
                label={labels[appState.language].yes}
                name='subjectOfPublicScandal'
                value="yes"
                checked={candidate['subjectOfPublicScandal'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="subjectOfPublicScandalNo"
                className="radio"
                label={labels[appState.language].no}
                name='subjectOfPublicScandal'
                value="no"
                checked={candidate['subjectOfPublicScandal'] === 'no'}

              />

              <div>{candidate.subjectOfPublicScandalDescription}</div>

            </Form.Field>


            <Form.Field required className="form-field">

              <label>{labels[appState.language].q7}</label>
              <Radio
                disabled={true}
                id="otherCitizenshipYes"
                className="radio"
                label={labels[appState.language].yes}
                name='otherCitizenship'
                value="yes"
                checked={candidate['otherCitizenship'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="otherCitizenshipNo"
                className="radio"
                label={labels[appState.language].no}
                name='otherCitizenship'
                value="no"
                checked={candidate['otherCitizenship'] === 'no'}

              />

              <div>{candidate.otherCitizenshipDescription}</div>

            </Form.Field>


            <Form.Field required className="form-field">

              <label>{labels[appState.language].q8}</label>
              <Radio
                disabled={true}
                id="candidateForPublicPositionYes"
                className="radio"
                label={labels[appState.language].yes}
                name='candidateForPublicPosition'
                value="yes"
                checked={candidate['candidateForPublicPosition'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="candidateForPublicPositionNo"
                className="radio"
                label={labels[appState.language].no}
                name='candidateForPublicPosition'
                value="no"
                checked={candidate['candidateForPublicPosition'] === 'no'}

              />

              <div>{candidate.candidateForPublicPositionDescription}</div>

            </Form.Field>


            <Form.Field required className="form-field">

              <label>{labels[appState.language].q9}</label>
              <Radio
                disabled={true}
                id="activeMemberInPoliticalPartyYes"
                className="radio"
                label={labels[appState.language].yes}
                name='activeMemberInPoliticalParty'
                value="yes"
                checked={candidate['activeMemberInPoliticalParty'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="activeMemberInPoliticalPartyNo"
                className="radio"
                label={labels[appState.language].no}
                name='activeMemberInPoliticalParty'
                value="no"
                checked={candidate['activeMemberInPoliticalParty'] === 'no'}

              />

              <div>{candidate.activeMemberInPoliticalPartyDescription}</div>

            </Form.Field>


            <Form.Field required className="form-field">

              <label>{labels[appState.language].q10}</label>
              <Radio
                disabled={true}
                id="affiliationWithPoliticalPartyYes"
                className="radio"
                label={labels[appState.language].yes}
                name='affiliationWithPoliticalParty'
                value="yes"
                checked={candidate['affiliationWithPoliticalParty'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="affiliationWithPoliticalPartyNo"
                className="radio"
                label={labels[appState.language].no}
                name='affiliationWithPoliticalParty'
                value="no"
                checked={candidate['affiliationWithPoliticalParty'] === 'no'}

              />

              <div>{candidate.affiliationWithPoliticalPartyDescription}</div>

            </Form.Field>

            {/* donatedToCampaigns */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q11}</label>
              <Radio
                disabled={true}
                id="donatedToCampaignsYes"
                className="radio"
                label={labels[appState.language].yes}
                name='donatedToCampaigns'
                value="yes"
                checked={candidate['donatedToCampaigns'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="donatedToCampaignsNo"
                className="radio"
                label={labels[appState.language].no}
                name='donatedToCampaigns'
                value="no"
                checked={candidate['donatedToCampaigns'] === 'no'}

              />

              <div>{candidate.donatedToCampaignsDescription}</div>

            </Form.Field>


            {/* involvedInBankruptcy */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q12}</label>
              <Radio
                disabled={true}
                id="involvedInBankruptcyYes"
                className="radio"
                label={labels[appState.language].yes}
                name='involvedInBankruptcy'
                value="yes"
                checked={candidate['involvedInBankruptcy'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="involvedInBankruptcyNo"
                className="radio"
                label={labels[appState.language].no}
                name='involvedInBankruptcy'
                value="no"
                checked={candidate['involvedInBankruptcy'] === 'no'}

              />

              <div>{candidate.involvedInBankruptcyDescription}</div>

            </Form.Field>


            {/* accussedOfTaxEvasion */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q13}</label>
              <Radio
                disabled={true}
                id="accussedOfTaxEvasionYes"
                className="radio"
                label={labels[appState.language].yes}
                name='accussedOfTaxEvasion'
                value="yes"
                checked={candidate['accussedOfTaxEvasion'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="accussedOfTaxEvasionNo"
                className="radio"
                label={labels[appState.language].no}
                name='accussedOfTaxEvasion'
                value="no"
                checked={candidate['accussedOfTaxEvasion'] === 'no'}

              />

              <div>{candidate.accussedOfTaxEvasionDescription}</div>

            </Form.Field>

            {/* audited */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q14}</label>
              <Radio
                disabled={true}
                id="auditedYes"
                className="radio"
                label={labels[appState.language].yes}
                name='audited'
                value="yes"
                checked={candidate['audited'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="auditedNo"
                className="radio"
                label={labels[appState.language].no}
                name='audited'
                value="no"
                checked={candidate['audited'] === 'no'}

              />

              <div>{candidate.auditedDescription}</div>

            </Form.Field>


            {/* involvedInGovernmentProject */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q15}</label>
              <Radio
                disabled={true}
                id="involvedInGovernmentProjectYes"
                className="radio"
                label={labels[appState.language].yes}
                name='involvedInGovernmentProject'
                value="yes"
                checked={candidate['involvedInGovernmentProject'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="involvedInGovernmentProjectNo"
                className="radio"
                label={labels[appState.language].no}
                name='involvedInGovernmentProject'
                value="no"
                checked={candidate['involvedInGovernmentProject'] === 'no'}

              />

              <div>{candidate.involvedInGovernmentProjectDescription}</div>

            </Form.Field>

            {/* involvedInNonGovernmentProject */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q16}</label>
              <Radio
                disabled={true}
                id="involvedInNonGovernmentProjectYes"
                className="radio"
                label={labels[appState.language].yes}
                name='involvedInNonGovernmentProject'
                value="yes"
                checked={candidate['involvedInNonGovernmentProject'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="involvedInNonGovernmentProjectNo"
                className="radio"
                label={labels[appState.language].no}
                name='involvedInNonGovernmentProject'
                value="no"
                checked={candidate['involvedInNonGovernmentProject'] === 'no'}

              />

              <div>{candidate.involvedInNonGovernmentProjectDescription}</div>

            </Form.Field>

            {/* appointedAsConsultantByGovernment */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q17}</label>
              <Radio
                disabled={true}
                id="appointedAsConsultantByGovernmentYes"
                className="radio"
                label={labels[appState.language].yes}
                name='appointedAsConsultantByGovernment'
                value="yes"
                checked={candidate['appointedAsConsultantByGovernment'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="appointedAsConsultantByGovernmentNo"
                className="radio"
                label={labels[appState.language].no}
                name='appointedAsConsultantByGovernment'
                value="no"
                checked={candidate['appointedAsConsultantByGovernment'] === 'no'}

              />

              <div>{candidate.appointedAsConsultantByGovernmentDescription}</div>

            </Form.Field>

            {/* fundedByNonGovernment */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q18}</label>
              <Radio
                disabled={true}
                id="fundedByNonGovernmentYes"
                className="radio"
                label={labels[appState.language].yes}
                name='fundedByNonGovernment'
                value="yes"
                checked={candidate['fundedByNonGovernment'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="fundedByNonGovernmentNo"
                className="radio"
                label={labels[appState.language].no}
                name='fundedByNonGovernment'
                value="no"
                checked={candidate['fundedByNonGovernment'] === 'no'}

              />

              <div>{candidate.fundedByNonGovernmentDescription}</div>

            </Form.Field>

            {/* fundedByGovernment */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q19}</label>
              <Radio
                disabled={true}
                id="fundedByGovernmentYes"
                className="radio"
                label={labels[appState.language].yes}
                name='fundedByGovernment'
                value="yes"
                checked={candidate['fundedByGovernment'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="fundedByGovernmentNo"
                className="radio"
                label={labels[appState.language].no}
                name='fundedByGovernment'
                value="no"
                checked={candidate['fundedByGovernment'] === 'no'}

              />

              <div>{candidate.fundedByGovernmentDescription}</div>

            </Form.Field>

            {/* bankAccountOutsideLebanon */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q20}</label>
              <Radio
                disabled={true}
                id="bankAccountOutsideLebanonYes"
                className="radio"
                label={labels[appState.language].yes}
                name='bankAccountOutsideLebanon'
                value="yes"
                checked={candidate['bankAccountOutsideLebanon'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="bankAccountOutsideLebanonNo"
                className="radio"
                label={labels[appState.language].no}
                name='bankAccountOutsideLebanon'
                value="no"
                checked={candidate['bankAccountOutsideLebanon'] === 'no'}

              />

              <div>{candidate.bankAccountOutsideLebanonDescription}</div>

            </Form.Field>

            {/* stripYourselfFromPoliticalImmunity */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q21}</label>
              <Radio
                disabled={true}
                id="stripYourselfFromPoliticalImmunityYes"
                className="radio"
                label={labels[appState.language].yes}
                name='stripYourselfFromPoliticalImmunity'
                value="yes"
                checked={candidate['stripYourselfFromPoliticalImmunity'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="stripYourselfFromPoliticalImmunityNo"
                className="radio"
                label={labels[appState.language].no}
                name='stripYourselfFromPoliticalImmunity'
                value="no"
                checked={candidate['stripYourselfFromPoliticalImmunity'] === 'no'}

              />

              <div>{candidate.stripYourselfFromPoliticalImmunityDescription}</div>

            </Form.Field>

            {/* removeYourselfFromConflictOfInterest */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].q22}</label>
              <Radio
                disabled={true}
                id="removeYourselfFromConflictOfInterestYes"
                className="radio"
                label={labels[appState.language].yes}
                name='removeYourselfFromConflictOfInterest'
                value="yes"
                checked={candidate['removeYourselfFromConflictOfInterest'] === 'yes'}

              />
              <Radio
                disabled={true}
                id="removeYourselfFromConflictOfInterestNo"
                className="radio"
                label={labels[appState.language].no}
                name='removeYourselfFromConflictOfInterest'
                value="no"
                checked={candidate['removeYourselfFromConflictOfInterest'] === 'no'}

              />


              <div>{candidate.removeYourselfFromConflictOfInterestDescription}</div>

            </Form.Field>












            <h2>{labels[appState.language].whereyoustand}</h2>
            <Divider />
            <p>{labels[appState.language].wintro}</p>

            {/* onSeparationOfReligionAndState */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w1}</label>
              <Radio
                disabled={true}
                id="onSeparationOfReligionAndStateWith"
                className="radio"
                label={labels[appState.language].with}
                name='onSeparationOfReligionAndState'
                value="with"
                checked={candidate['onSeparationOfReligionAndState'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onSeparationOfReligionAndStateAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onSeparationOfReligionAndState'
                value="against"
                checked={candidate['onSeparationOfReligionAndState'] === 'against'}

              />
              <div>{candidate.onSeparationOfReligionAndStateDescription}</div>

            </Form.Field>


            {/* onLegalizingCivilMarriage */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w2}</label>
              <Radio
                disabled={true}
                id="onLegalizingCivilMarriageWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLegalizingCivilMarriage'
                value="with"
                checked={candidate['onLegalizingCivilMarriage'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLegalizingCivilMarriageAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLegalizingCivilMarriage'
                value="against"
                checked={candidate['onLegalizingCivilMarriage'] === 'against'}

              />
              <div>{candidate.onLegalizingCivilMarriageDescription}</div>

            </Form.Field>


            {/* onLegalizingMarijuana */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w3}</label>
              <Radio
                disabled={true}
                id="onLegalizingMarijuanaWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLegalizingMarijuana'
                value="with"
                checked={candidate['onLegalizingMarijuana'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLegalizingMarijuanaAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLegalizingMarijuana'
                value="against"
                checked={candidate['onLegalizingMarijuana'] === 'against'}

              />
              <div>{candidate.onLegalizingMarijuanaDescription}</div>

            </Form.Field>


            {/* onLGBTQ */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w4}</label>
              <Radio
                disabled={true}
                id="onLGBTQWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLGBTQ'
                value="with"
                checked={candidate['onLGBTQ'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLGBTQAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLGBTQ'
                value="against"
                checked={candidate['onLGBTQ'] === 'against'}

              />
              <div>{candidate.onLGBTQDescription}</div>

            </Form.Field>

            {/* onLegalizingAbortion */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w5}</label>
              <Radio
                disabled={true}
                id="onLegalizingAbortionWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLegalizingAbortion'
                value="with"
                checked={candidate['onLegalizingAbortion'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLegalizingAbortionAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLegalizingAbortion'
                value="against"
                checked={candidate['onLegalizingAbortion'] === 'against'}

              />
              <div>{candidate.onLegalizingAbortionDescription}</div>

            </Form.Field>

            {/* onUnifyingPersonalStatusLaws */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w6}</label>
              <Radio
                disabled={true}
                id="onUnifyingPersonalStatusLawsWith"
                className="radio"
                label={labels[appState.language].with}
                name='onUnifyingPersonalStatusLaws'
                value="with"
                checked={candidate['onUnifyingPersonalStatusLaws'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onUnifyingPersonalStatusLawsAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onUnifyingPersonalStatusLaws'
                value="against"
                checked={candidate['onUnifyingPersonalStatusLaws'] === 'against'}

              />
              <div>{candidate.onUnifyingPersonalStatusLawsDescription}</div>

            </Form.Field>

            {/* onUnifyingInheritanceLaws */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w7}</label>
              <Radio
                disabled={true}
                id="onUnifyingInheritanceLawsWith"
                className="radio"
                label={labels[appState.language].with}
                name='onUnifyingInheritanceLaws'
                value="with"
                checked={candidate['onUnifyingInheritanceLaws'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onUnifyingInheritanceLawsAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onUnifyingInheritanceLaws'
                value="against"
                checked={candidate['onUnifyingInheritanceLaws'] === 'against'}

              />
              <div>{candidate.onUnifyingInheritanceLawsDescription}</div>

            </Form.Field>

            {/* onProstitution */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w8}</label>
              <Radio
                disabled={true}
                id="onProstitutionWith"
                className="radio"
                label={labels[appState.language].with}
                name='onProstitution'
                value="with"
                checked={candidate['onProstitution'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onProstitutionAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onProstitution'
                value="against"
                checked={candidate['onProstitution'] === 'against'}

              />
              <div>{candidate.onProstitutionDescription}</div>

            </Form.Field>

            {/* onFreedomOfPress */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w9}</label>
              <Radio
                disabled={true}
                id="onFreedomOfPressWith"
                className="radio"
                label={labels[appState.language].with}
                name='onFreedomOfPress'
                value="with"
                checked={candidate['onFreedomOfPress'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onFreedomOfPressAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onFreedomOfPress'
                value="against"
                checked={candidate['onFreedomOfPress'] === 'against'}

              />
              <div>{candidate.onFreedomOfPressDescription}</div>

            </Form.Field>

            {/* onFreedomOfSpeech */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w10}</label>
              <Radio
                disabled={true}
                id="onFreedomOfSpeechWith"
                className="radio"
                label={labels[appState.language].with}
                name='onFreedomOfSpeech'
                value="with"
                checked={candidate['onFreedomOfSpeech'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onFreedomOfSpeechAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onFreedomOfSpeech'
                value="against"
                checked={candidate['onFreedomOfSpeech'] === 'against'}

              />
              <div>{candidate.onFreedomOfSpeechDescription}</div>

            </Form.Field>



            {/* onIncorporationOfTechnology */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w11}</label>
              <Radio
                disabled={true}
                id="onIncorporationOfTechnologyWith"
                className="radio"
                label={labels[appState.language].with}
                name='onIncorporationOfTechnology'
                value="with"
                checked={candidate['onIncorporationOfTechnology'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onIncorporationOfTechnologyAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onIncorporationOfTechnology'
                value="against"
                checked={candidate['onIncorporationOfTechnology'] === 'against'}

              />
              <div>{candidate.onIncorporationOfTechnologyDescription}</div>

            </Form.Field>


            {/* onWomenRights */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w12}</label>
              <Radio
                disabled={true}
                id="onWomenRightsWith"
                className="radio"
                label={labels[appState.language].with}
                name='onWomenRights'
                value="with"
                checked={candidate['onWomenRights'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onWomenRightsAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onWomenRights'
                value="against"
                checked={candidate['onWomenRights'] === 'against'}

              />
              <div>{candidate.onWomenRightsDescription}</div>

            </Form.Field>


            {/* onChildrenRights */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w13}</label>
              <Radio
                disabled={true}
                id="onChildrenRightsWith"
                className="radio"
                label={labels[appState.language].with}
                name='onChildrenRights'
                value="with"
                checked={candidate['onChildrenRights'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onChildrenRightsAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onChildrenRights'
                value="against"
                checked={candidate['onChildrenRights'] === 'against'}

              />
              <div>{candidate.onChildrenRightsDescription}</div>

            </Form.Field>


            {/* onLebaneseMothers */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w14}</label>
              <Radio
                disabled={true}
                id="onLebaneseMothersWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLebaneseMothers'
                value="with"
                checked={candidate['onLebaneseMothers'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLebaneseMothersAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLebaneseMothers'
                value="against"
                checked={candidate['onLebaneseMothers'] === 'against'}

              />
              <div>{candidate.onLebaneseMothersDescription}</div>

            </Form.Field>

            {/* onStatelessPeople */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w15}</label>
              <Radio
                disabled={true}
                id="onStatelessPeopleWith"
                className="radio"
                label={labels[appState.language].with}
                name='onStatelessPeople'
                value="with"
                checked={candidate['onStatelessPeople'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onStatelessPeopleAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onStatelessPeople'
                value="against"
                checked={candidate['onStatelessPeople'] === 'against'}

              />
              <div>{candidate.onStatelessPeopleDescription}</div>

            </Form.Field>

            {/* onPalestinianRefugees */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w16}</label>
              <Radio
                disabled={true}
                id="onPalestinianRefugeesWith"
                className="radio"
                label={labels[appState.language].with}
                name='onPalestinianRefugees'
                value="with"
                checked={candidate['onPalestinianRefugees'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onPalestinianRefugeesAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onPalestinianRefugees'
                value="against"
                checked={candidate['onPalestinianRefugees'] === 'against'}

              />
              <div>{candidate.onPalestinianRefugeesDescription}</div>

            </Form.Field>

            {/* onGrantingSyriansRefugeeStatus */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w17}</label>
              <Radio
                disabled={true}
                id="onGrantingSyriansRefugeeStatusWith"
                className="radio"
                label={labels[appState.language].with}
                name='onGrantingSyriansRefugeeStatus'
                value="with"
                checked={candidate['onGrantingSyriansRefugeeStatus'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onGrantingSyriansRefugeeStatusAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onGrantingSyriansRefugeeStatus'
                value="against"
                checked={candidate['onGrantingSyriansRefugeeStatus'] === 'against'}

              />
              <div>{candidate.onGrantingSyriansRefugeeStatusDescription}</div>

            </Form.Field>

            {/* onGovernmentLootedFunds */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w18}</label>
              <Radio
                disabled={true}
                id="onGovernmentLootedFundsWith"
                className="radio"
                label={labels[appState.language].with}
                name='onGovernmentLootedFunds'
                value="with"
                checked={candidate['onGovernmentLootedFunds'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onGovernmentLootedFundsAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onGovernmentLootedFunds'
                value="against"
                checked={candidate['onGovernmentLootedFunds'] === 'against'}

              />
              <div>{candidate.onGovernmentLootedFundsDescription}</div>

            </Form.Field>

            {/* onEnvironment */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w19}</label>
              <Radio
                disabled={true}
                id="onEnvironmentWith"
                className="radio"
                label={labels[appState.language].with}
                name='onEnvironment'
                value="with"
                checked={candidate['onEnvironment'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onEnvironmentAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onEnvironment'
                value="against"
                checked={candidate['onEnvironment'] === 'against'}

              />
              <div>{candidate.onEnvironmentDescription}</div>

            </Form.Field>

            {/* onMeritocracy */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w20}</label>
              <Radio
                disabled={true}
                id="onMeritocracyWith"
                className="radio"
                label={labels[appState.language].with}
                name='onMeritocracy'
                value="with"
                checked={candidate['onMeritocracy'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onMeritocracyAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onMeritocracy'
                value="against"
                checked={candidate['onMeritocracy'] === 'against'}

              />
              <div>{candidate.onMeritocracyDescription}</div>

            </Form.Field>


            {/* onTheConstitution */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w21}</label>
              <Radio
                disabled={true}
                id="onTheConstitutionWith"
                className="radio"
                label={labels[appState.language].with}
                name='onTheConstitution'
                value="with"
                checked={candidate['onTheConstitution'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onTheConstitutionAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onTheConstitution'
                value="against"
                checked={candidate['onTheConstitution'] === 'against'}

              />
              <div>{candidate.onTheConstitutionDescription}</div>

            </Form.Field>


            {/* onElectingPresidentByThePeople */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w22}</label>
              <Radio
                disabled={true}
                id="onElectingPresidentByThePeopleWith"
                className="radio"
                label={labels[appState.language].with}
                name='onElectingPresidentByThePeople'
                value="with"
                checked={candidate['onElectingPresidentByThePeople'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onElectingPresidentByThePeopleAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onElectingPresidentByThePeople'
                value="against"
                checked={candidate['onElectingPresidentByThePeople'] === 'against'}

              />
              <div>{candidate.onElectingPresidentByThePeopleDescription}</div>

            </Form.Field>



            {/* onPeggingLiraToUsd */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w23}</label>
              <Radio
                disabled={true}
                id="onPeggingLiraToUsdWith"
                className="radio"
                label={labels[appState.language].with}
                name='onPeggingLiraToUsd'
                value="with"
                checked={candidate['onPeggingLiraToUsd'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onPeggingLiraToUsdAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onPeggingLiraToUsd'
                value="against"
                checked={candidate['onPeggingLiraToUsd'] === 'against'}

              />
              <div>{candidate.onPeggingLiraToUsdDescription}</div>

            </Form.Field>

            {/* onLebaneseDiaspora */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w24}</label>
              <Radio
                disabled={true}
                id="onLebaneseDiasporaWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLebaneseDiaspora'
                value="with"
                checked={candidate['onLebaneseDiaspora'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLebaneseDiasporaAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLebaneseDiaspora'
                value="against"
                checked={candidate['onLebaneseDiaspora'] === 'against'}

              />
              <div>{candidate.onLebaneseDiasporaDescription}</div>

            </Form.Field>

            {/* onFemaleQuota */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w25}</label>
              <Radio
                disabled={true}
                id="onFemaleQuotaWith"
                className="radio"
                label={labels[appState.language].with}
                name='onFemaleQuota'
                value="with"
                checked={candidate['onFemaleQuota'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onFemaleQuotaAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onFemaleQuota'
                value="against"
                checked={candidate['onFemaleQuota'] === 'against'}

              />
              <div>{candidate.onFemaleQuotaDescription}</div>

            </Form.Field>

            {/* onNewElectoralLaw */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w26}</label>
              <Radio
                disabled={true}
                id="onNewElectoralLawWith"
                className="radio"
                label={labels[appState.language].with}
                name='onNewElectoralLaw'
                value="with"
                checked={candidate['onNewElectoralLaw'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onNewElectoralLawAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onNewElectoralLaw'
                value="against"
                checked={candidate['onNewElectoralLaw'] === 'against'}

              />
              <div>{candidate.onNewElectoralLawDescription}</div>

            </Form.Field>

            {/* onEarlyParliamentaryElections */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w27}</label>
              <Radio
                disabled={true}
                id="onEarlyParliamentaryElectionsWith"
                className="radio"
                label={labels[appState.language].with}
                name='onEarlyParliamentaryElections'
                value="with"
                checked={candidate['onEarlyParliamentaryElections'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onEarlyParliamentaryElectionsAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onEarlyParliamentaryElections'
                value="against"
                checked={candidate['onEarlyParliamentaryElections'] === 'against'}

              />
              <div>{candidate.onEarlyParliamentaryElectionsDescription}</div>

            </Form.Field>

            {/* onTheLebaneseRevolution */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w28}</label>
              <Radio
                disabled={true}
                id="onTheLebaneseRevolutionWith"
                className="radio"
                label={labels[appState.language].with}
                name='onTheLebaneseRevolution'
                value="with"
                checked={candidate['onTheLebaneseRevolution'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onTheLebaneseRevolutionAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onTheLebaneseRevolution'
                value="against"
                checked={candidate['onTheLebaneseRevolution'] === 'against'}

              />
              <div>{candidate.onTheLebaneseRevolutionDescription}</div>

            </Form.Field>

            {/* onLebnonAndSyria */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w29}</label>
              <Radio
                disabled={true}
                id="onLebnonAndSyriaWith"
                className="radio"
                label={labels[appState.language].with}
                name='onLebnonAndSyria'
                value="with"
                checked={candidate['onLebnonAndSyria'] === 'with'}

              />
              <Radio
                disabled={true}
                id="onLebnonAndSyriaAgainst"
                className="radio"
                label={labels[appState.language].against}
                name='onLebnonAndSyria'
                value="against"
                checked={candidate['onLebnonAndSyria'] === 'against'}

              />
              <div>{candidate.onLebnonAndSyriaDescription}</div>

            </Form.Field>


            {/* roleModel */}
            <Form.Field required className="form-field">

              <label>{labels[appState.language].w30}</label>
              <div>{candidate.roleModel}</div>

            </Form.Field>

          </Form>



        </div>
      </div>


    </Container>
  )
}

export default (props) => {

  const [, doAction] = useContext(CTX);

  const updateUser = (user) => {
    doAction({ type: message_types.SET_USER, payload: user })
  }

  return (
    <Authenticator hide={[SignUp, SignIn, Greetings]}>
      <CustomSignIn updateUser={updateUser} override={'SignIn'} />
      <CustomSignUp override={'SignUp'} />
      <Application {...props} />
    </Authenticator>
  )
}

