import { message_types } from "./constants";

export default (state, action) => {
  switch (action.type) {

    case message_types.SET_USER:
      if (!action.payload) {
        return { ...state, user: '', groups: [] }
      }
      const user = action.payload.username;
      const groups = action.payload.signInUserSession.idToken.payload["cognito:groups"] || [];
      return { ...state, user, groups };

    case message_types.SET_SELECT_PAGE:
      return { ...state, selected_page: action.payload || 'home' };

    case message_types.SET_LANGUAGE:
      return { ...state, language: action.payload || 'en' };

    default:
      return { ...state };
  }
}
