export default {
  en: {
    yes: "Yes",
    no: "No",
    with: "With",
    against: "Against",
    publicProfile: "REQUIRED: Public Profile",
    firstName: "First Name",
    "lastName": "Last Name",
    "preferredEmail": "Preferred Email",
    "preferredEmailFormat": "Preferred email should be of the format mail@mail.com",
    "preferredPhoneNumber": "Preferred Phone Number",
    "dateOfBirth": "Date of Birth (E.g September 26, 1983) ",
    "dateOfBirthFormat": "Date of birth should be of the format 1985-12-21",
    runFor: "What do you want to run for ?",
    minister: "Minister",
    parliament: "Parliament",
    runForDescription: "Type Of Minister:",
    profilePicture: "Profile Picture",
    wrongProfilePictureSize : "Your picture is not the required size of 1024x1024",
    tr1: "- Be sure to black out any information you deem to seem sensitive (E.g Tax ID, Social Security Number, etc)",
    tr2: "-  Please attach any documents that explain special filing statuses you may have, should they exist.",
    vs1: "- Be sure to black out any information you deem to seem sensitive",
    titleQuestions: "All documents will remain private unless you authorize their release. Shortly after you submit your application a special team from The Initiative will contact you to coordinate your public presentation which will only be published following your final approval. Until then all documents will never be made available publicly.",
    q1: 'Have you ever been subjected to discrimination?',
    q2: 'Have you ever subjected anyone to discrimination?',
    q3: 'Have you ever been accused of, or been questioned in connection with, a crime in Lebanon or abroad?',
    q4: 'Have you ever been convicted of a crime in Lebanon or abroad?',
    q5: 'Have you ever violated any Law, in Lebanon or abroad?',
    q6: 'Have you ever been the subject of a public scandal in Lebanon or abroad?',
    q7: 'Have you ever applied for immigration? And/or do you have other citizenship?',
    q8: 'Have you ever been a candidate for any public or governmental position, in Lebanon or abroad?',
    q9: 'Are you, or have you ever been, an active member in a political party in Lebanon or abroad?',
    q10: 'Have you had any affiliation whatsoever with any political party in Lebanon or abroad?',
    q11: 'Have you donated either your time, skills or money to any campaigns, political, constitutional, legal or otherwise, in Lebanon or abroad?',
    q12: 'Have you, a family member, or a legal entity that you are affiliated with ever been involved in bankruptcy cases, in Lebanon or abroad?',
    q13: 'Have you, a family member, or a legal entity that you are affiliated with ever been accused of tax evasion, in Lebanon or abroad?',
    q14: 'Have you, a family member, or a legal entity that you are affiliated with ever been audited in any way by the Lebanese government or any foreign government?',
    q15: 'Have you, a family member, or a legal entity that you are affiliated with ever been involved in a project involving the Lebanese government, or any foreign government?',
    q16: 'Have you, a family member, or a legal entity that you are affiliated with ever been involved in a project involving a local or foreign non-governmental organization?',
    q17: 'Have you, a family member, or a legal entity that you are affiliated with ever been appointed as a consultant by the Lebanese or any foreign government?',
    q18: 'Have you, a family member, or a legal entity that you are affiliated with ever been involved in a project funded by a Lebanese or foreign non-governmental organization?',
    q19: 'Have you, a family member, or a legal entity that you are affiliated with ever been involved in a project funded by a Lebanese or foreign government?',
    q20: 'Do you, a family member, or a legal entity that you are affiliated with have a bank account in a country other than Lebanon which you do not conduct any business in?',
    q21: 'If elected or appointed into a public position, are you willing to strip yourself from political immunity, if accused of a crime?',
    q22: 'If elected or appointed into a public position do you pledge to remove yourself from any companies, initiatives or interests legally and officially that will be a conflict of interest in your current position? And do you also pledge to recuse yourself from any decision-making apparatus where a conflict of interest may arise and nominate someone in your stead for said case or cases?',
    w1: 'On the separation of religion and state',
    w2: 'On legalizing civil marriage',
    w3: 'On legalizing marijuana',
    w4: 'On enacting laws to protect the LGBTQ+ community and ensure their equal rights amongst all Lebanese citizens',
    w5: 'On legalizing abortion',
    w6: 'On unifying personal status laws',
    w7: 'On unifying inheritance laws',
    w8: 'On enacting laws governing the practice of prostitution',
    w9: 'On the fundamental right of absolute freedom of press',
    w10: 'On enacting laws to protect absolute freedom of speech in any and all cases',
    w11: 'On the incorporation of technology in the public sector',
    w12: 'On updating laws protecting women’s rights',
    w13: 'On updating laws to protect children’s rights',
    w14: 'On Lebanese mothers granting Lebanese citizenship to their Children',
    w15: 'On the enactment of laws governing the status of stateless persons residing in Lebanese Territory',
    w16: 'On the ability to apply for Lebanese Citizenship of Palestinian Refugees residing in Lebanon',
    w17: 'On granting “Refugee” status to the Syrians who have fled Syria because of the war',
    w18: 'On legislation to retrieve government looted funds',
    w19: 'On putting forth and passing legislation to ensure environmental protection and establish climate standard objectives',
    w20: 'On the implementation of the principle of Meritocracy',
    w21: 'On the Lebanese constitution, do you believe it works for or against the Lebanese people?',
    w22: 'On the Lebanese people electing the president themselves through a national vote',
    w23: 'On the pegging of the Lebanese Lira to the USD',
    w24: 'On the granting of the Lebanese Diaspora a specific number of representatives in Parliament and in Government',
    w25: 'On the establishment of a minimum quota for female participation in Parliament',
    w26: 'On enacting a new electoral law',
    w27: 'On conducting early parliamentary elections',
    w28: 'On the Lebanese Revolution of 17 October',
    w29: 'On the reestablishment of the official Lebanese/Syrian relationship',
    w30: 'Name your role model',


    d1: 'REQUIRED: Why should we vote for you? (5 points) Please upload a minimum of five pages, maximum of ten pages, document about yourself to be divided as follows:',
    d11: '- One page up to three pages on your background and who you are.',
    d12: '- One page up to three pages on what you believe are the issues holding Lebanon back.',
    d13: '- One page up to three pages how you intend to solve the issues',
    d14: '- One final page for your concluding statement.',

    d2: 'REQUIRED: Up to Date Criminal Record (5 points)',
    d3: 'REQUIRED: Sign The Pledge you can download The Pledge here to sign. (5 points)',
    d4: 'Tax Returns for the past five years, both personal and those of any legal entity, corporate or otherwise, that you are affiliated with, in Lebanon or abroad (50 points)',
    d5: 'Full detailed Statement from the Commercial Registry showing all legal entities that you are affiliated with, in Lebanon or abroad (15 points)',
    d6: 'Certificate of Denial of Ownership from the relevant authorities in Lebanon and abroad (10 points)',
    d7: 'Certificate from the Vehicle Registration office, in Lebanon and abroad, showing all cars owned (10 points)',

    explanation: 'Yes answers require an explanation',
    submit: 'Submit',
    cancel: 'Cancel',
    save: "Save",
    details: 'Should you wish to elaborate',
    elaborate: 'Please tell us more',
    selectfile: 'Please select a file',
    selectanswer: 'Please select an answer',
    entervalue: 'Please enter a value',
    errorheader: 'Your application has not been submitted',
    error: 'Please review your application and make sure you fill out all the required fields.',
    missingfields: function(number) {
      return `There are ${number} missing fields`
    },
    missingOnOtherPages: 'The missing fields may be on a different page',
    pledgelink: 'https://theinitiativepublic.s3-us-west-2.amazonaws.com/The+Pledge.pdf',
    documents: 'DOCUMENTS REQUESTED',
    questionnaire: 'QUESTIONNAIRE',
    whereyoustand: 'WHERE YOU STAND',
    qintro: "The following are YES or NO questions. When answered yes, and in some cases no, you have 100 words or less to elaborate in each respective question’s text box. These questions are in no particular order of importance.",
    wintro: 'Should you believe your view needs explanation you have 100 words or less to do so in each respective issue’s text box. These are in no particular order of importance.',
    
  },
  ar: {
    publicProfile: "حساب عام",
    firstName: "الإسم",
    lastName: "الشهرة",
    preferredEmail: "البريد الإلكتروني المفضل",
    preferredPhoneNumber: "رقم الهاتف المفضل",
    dateOfBirth: "تاريخ الولادة (مثلاً ٢٦ أيلول ١٩٨٣)",
    runFor: "لأي منصب تريد أن تترشح؟",
    minister: "وزير",
    parliament: "برلمان",
    runForDescription: "نوع الوزير",
    profilePicture: "الصورة الشخصية",
    wrongProfilePictureSize: "الصورة ليست بالحجم المطلوب ١٠٢٤- ١٠٢٤",
    titleQuestions: "ستبقى كافّة المستندات سرّية إلى حين إعطاء صلاحيّة نشرها. فبعد وقتٍ قصيرٍ من تقديم طلبكم، سيتواصل معكم فريق خاصّ من المبادرة للقيام بالتّنسيق في ما يخصّ تقديمكم العلنيّ والذي سيُعرض حصريّاً بعد موافقتكم النهائيّة. وحتّى ذلك الحين، لن يتمّ عرض أي من المستندات إلى العلن",
    tr1: "الرّجاء التّأكّد من محو/إخفاء أي معلومات/بيانات  حسّاسة (مثلاً الرقم الضريبي، ورقم الضمان الاجتماعي، إلخ...) -",
    tr2: "الرّجاء إرفاق أي مستندات توضّح الحالات الخاصة لحفظ الملفّات التي تملكونها في حال وُجِدَت -",
    vs1: "الرّجاء التّأكّد من محو/إخفاء أي معلومات/بيانات  حسّاسة -",
    q1: 'هل تعرضّت يوماً الى التمييز؟',
    q2: 'هل عرضّت أحداً يوماً الى التمييز؟',
    q3: 'هل اتُهمت يوماً بجريمة أو استُجوبت في تحقيق له علاقة بجريمة، في لبنان أو الخارج؟',
    q4: 'هل أدِنت يوماً بجريمة في لبنان أو الخارج؟',
    q5: 'هل انتهكت يوماً أي قانون، في لبنان أو الخارج؟',
    q6: 'هل تعرضت يوماً لفضيحة في لبنان أو الخارج؟',
    q7: 'هل قدّمت يوماً طلب هجرة؟ وأو هل تحمل جنسية أخرى؟',
    q8: 'هل كنت مرشحاً يوماً لمنصب عام أو حكومي، في لبنان أو الخارج؟',
    q9: 'هل أنت الآن فرداً ناشطاً أو كنت فرداً ناشطاً في أي حزب سياسي في لبنان أو الخارج؟',
    q10: 'هل ربطتك أية علاقة مهما كانت بأي حزب سياسي في لبنان أو الخارج؟',
    q11: 'هل تبرعت بوقتك أو مهاراتك أو مالك لأية حملات سياسية أو دستورية أو قانونية أو سواها، في لبنان أو الخارج؟',
    q12: 'هل كان لك أنت أو فرد في العائلة أو أي كيان قانوني تربطك به علاقة، أي ارتباط بقضايا إفلاسية، في لبنان أو الخارج؟',
    q13: 'هل اتُهمت يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة بالتهرب الضريبي، في لبنان أو الخارج؟',
    q14: 'هل خضعت يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة للتدقيق في الحسابات من أية ناحية أمام الحكومة اللبنانية أو أية حكومة أجنبية؟',
    q15: 'هل شاركت يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة في عمل كانت للحكومة اللبنانية أو لأي حكومة أجنبية دور فيه؟',
    q16: 'هل شاركت يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة في عمل لمنظمة غير حكومية محلية أو أجنبية، دور فيه؟',
    q17: 'هل تمّ تعيينك يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة كمستشار من قبل الحكومة اللبنانية أو أي حكومة أجنبية؟',
    q18: 'هل شاركت يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة في عمل من تمويل منظمة غير حكومية لبنانية أو أجنبية؟',
    q19: 'هل شاركت يوماً أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة في عمل من تمويل الحكومة اللبنانية أو حكومة أجنبية؟',
    q20: 'هل لديك أنت أو فرد من العائلة أو أي كيان قانوني تربطك به علاقة حساب مصرفي خارج لبنان وحيث لا تمارس أية أعمال؟',
    q21: 'إن انتُخبت أو عُينت في أي منصب عام، فهل أنت مستعد للتخلي عن الحصانة السياسية في حال تم اتهامك بأية جريمة؟',
    q22: 'إن انتُخبت أو عُينت في أي منصب عام، فهل تتعهد بأن تنسحب من أية شركات أو مبادرات أو مصالح، قانونياً ورسمياً، قد تطرح تضارباً للمصالح في منصبك الحالي؟ وهل تتعهد أيضاً بأن تعفي نفسك من أي جهاز قرار قد ينشأ فيه تضارب للمصالح مع منصبك وترشّح شخصاً بدلاً عنك لتولي القضية أو القضايا؟',
    w1: '.مسألة فصل الدين عن الدولة',
    w2: '.مسألة تشريع الزواج المدني',
    w3: '.مسألة تشريع الماريجوانا',
    w4: '.بفئاتهم كلها وتضمن حقوقهم المتساوية مع جميع المواطنين اللبنانيينLGBTQ+مسألة سن قوانين تحمي مجتمع الـ',
    w5: '.مسألة تشريع الإجهاض',
    w6: '.مسألة توحيد قوانين الأحوال الشخصية',
    w7: '.مسألة توحيد قوانين الإرث',
    w8: '.مسألة سن قوانين ترعى عمل الدعارة',
    w9: '.مسألة اعتبار الحرية التامة للصحافة حق اساسي',
    w10: '.مسألة سن قوانين لحماية الحرية التامة للتعبير في أية وكل الأحوال',
    w11: '.مسألة دمج التكنولوجيا في القطاع العام',
    w12: '.مسألة تحديث قوانين حماية حقوق النساء',
    w13: '.مسألة تحديث قوانين حماية حقوق الأطفال',
    w14: '.مسألة تقديم الأمهات اللبنانيات الجنسية اللبنانية إلى أولادهن',
    w15: '.مسألة سن قوانين ترعى أحوال الأشخاص العديمي الجنسية الذي يسكنون في الأراضي اللبنانية',
    w16: '.مسألة إمكانية اللاجئين الفلسطينيين الذين يسكنون في لبنان تقديم طلب الحصول على الجنسية اللبنانية',
    w17: '.مسألة منح السوريين الذين هربوا من سوريا بسبب الحرب صفة "لاجىء"',
    w18: '.مسألة سن قوانين لاسترجاعالأموال العامة المنهوبة',
    w19: '.مسألة سن قوانين وتفعيلها لضمان الحماية البيئية وإرساء أهداف مناخية معيارية',
    w20: '.مسألة تطبيق مبدأ الجدارة',

    w21: 'مسألة الدستور اللبناني، هل تؤمن بأنه يخدم مصالح الشعب اللبناني أم يعارضها؟',
    w22: '.مسألة انتخاب الرئيس مباشرةً من قبل الشعب',
    w23: '.مسألة تثبيت الليرة اللبنانية مقارنةً بالدولار الأميركي',
    w24: '.مسألة منح الجالية اللبنانية عدداً محدداً من الممثلين في المجلس النيابي والحكومة',
    w25: '.مسألة منح النساء نسبة دنيا في المجلس النيابي',
    w26: '.مسألة سن قانون انتخابي جديد',
    w27: '.مسألة الانتخابات النيابية المبكرة',
    w28: '.مسألة ثورة 17 تشرين الأول اللبنانية',
    w29: '.مسألة إعادة إرساء العلاقة الرسمية بين سوريا ولبنان',
    w30: '.اذكر مثالك الأعلى',

    d1: 'ملزم تقديمها: لمَ يجب أن نصوت لك؟ (5 نقاط) الرجاء تحميل مستند من خمس صفحات على الأقل وعشر صفحات على الأكثر، مقسم كالتالي',
    d11: 'من صفحة إلى ثلاث صفحات عن خلفيتك ومن أنت -',
    d12: 'من صفحة إلى ثلاث صفحات عن الأسباب التي ترى أنها تشكل عائقاً أمام تقدّم لبنان -',
    d13: 'من صفحة إلى ثلاث صفحات عن الطريقة التي تنوي بها حل هذه المشكلات -',
    d14: 'صفحة نهائية عن حجتك الختامية -',

    d2: 'ملزم تقديمها: بيان سجل عدلي حديث (5 نقاط)',
    d3: 'توقيع التعهد. يمكنك تنزيل "التعهد" هنا لتوقيعه. (5 نقاط)',
    d4: 'التصاريح الضريبية للسنوات الخمس الأخيرة، سواء عادت لشخصك أو لأي كيان قانوني آخر أو شركة أخرى أو سوى ذلك مما لك علاقة به، في لبنان أو الخارج. (50 نقطة)',
    d5: 'افادة شاملة و مفصّلة من "السجل التجاري" تظهر كل الكيانات القانونية التي لك علاقة بها، في لبنان أو الخارج. (15 نقطة)',
    d6: 'افادة نفي ملكية من السلطات المختصة في لبنان والخارج. (10 نقاط)',
    d7: 'افادة من مصلحة تسجيل السيارات والاليات تُظهر جميع السيارات والاليات التي تملكها في لبنان والخارج. (10 نقاط)',

    submit: 'تطبيق',
    cancel: 'إلغاء',
    save: 'إحفظ',
    selectfile: 'يرجى تحميل ملف',
    selectanswer: 'الرجاء اختيار إجابة',
    entervalue: 'يرجى إدخال قيمة',
    errorheader: 'لم يتم تقديم طلبك',
    error: 'يرجى مراجعة طلبك والتأكد من ملء جميع الحقول المطلوبة.',
    missingfields: function(number) {
      return `هناك مجالات ${number} لم تتم تعبئتها`
    },
    missingOnOtherPages: 'قد تكون المجالات الناقصة في صفحة أخرى',
    pledgelink: 'https://theinitiativepublic.s3-us-west-2.amazonaws.com/%D8%A7%D9%84%D8%AA%D8%B9%D9%87%D8%AF.docx',

    explanation: 'الجواب يتطلب المزيد من التفسير',
    details: 'إذا كنت ترغب في إعطاء مزيد من التفاصيل',
    elaborate: 'يرجى توضيح',
    with: 'مع',
    against: 'ضد',
    yes: 'نعم',
    no: 'كلا',
    documents: 'المستندات المطلوبة',
    questionnaire: 'استمارة الأسئلة',
    whereyoustand: 'موقفك',
    qintro: "الأسئلة التالية يجاب عنها بنعم أو لا. عندما تجيب بنعم، لديك 100 كلمة أو أقل للشرح في خانة السؤال. هذه الأسئلة ليست مرتبة وفقاً للأهمية.",
    wintro: 'إن كنت تعتقد أن رأيك يحتاج إلى الشرح، فلديك 100 كلمة أو أقل لفعل ذلك في خانة كل مسألة. ليست هذه مرتبة وفقاً للأهمية.'
  },

}
