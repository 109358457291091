import React from 'react'
import './index.css'

export default () => {

  return (
    <div className="application-textblock-ar-container">
      <p>.إن كنت لن تقدّم مع طلبك أياً من المستندات المطلوبة، فيجب إبراز تبرير كامل كتابةً عند تقديم الطلب</p>

      <p>.بالنسبة الى التصاريح الضريبية إن كان السبب في حال عدم التقدّم بها أنك تحتاج إلى الوقت لتحضير المستندات، فيجب أن تقدّم مهلة محددة لموعد تقديمها. هذه المهلة يجب أن تُقدّم في مستند محمّل إلى قسم التصاريح الضريبية</p>

      <p>.تذكّر، باستثناء المستندات الملزم تقديمها المذكورة ادناه، لن تلزم بتقديم أي مستند، بل انت تفعل ذلك بملء إرادتك، وان خيارك هذا يشكّل جزءاً من عرضك للناس ونترك لك خيار أن تقرر كيف تقدّم نفسك</p>

    </div>
  )
}
