import React, { useContext, useEffect } from 'react'
import { Container } from 'semantic-ui-react'
import { CTX } from '../state/store';

import './index.css'

import { useLocation } from "react-router-dom";
import { message_types } from '../state/constants';


export default () => {

  const [appState, doAction] = useContext(CTX);
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname.substr(1);
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Container>
      <div className="candidates-container">
        {appState.language === 'en' &&
          <p>
            We are currently receiving applications, soon this page will have
            candidates for you to decide whether to grant them the power of the Initiative or not!
        </p>
        }

        {appState.language === 'ar' &&
          <p>
            نتلقى حاليا الطلبات وقريباً ستجدون على هذه الصفحة أسماء المرشحين. يمكنكم من خلالها التقرير ما إذا كان سيتم منحهم قوة المبادرة
        </p>
        }
      </div>
    </Container>
  )
}
