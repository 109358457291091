import React, { createContext, useReducer } from 'react';
import Reducer from './reducer';


const CTX = createContext();

export default (props) => {

  const initialState = {
    user: '',
    groups: [],
    selected_page: 'home',
    language: ''
  }

  const stateHook = useReducer(Reducer, initialState);

  return (
    <CTX.Provider value={stateHook}>
      {props.children}
    </CTX.Provider>
  )
}

export { CTX };
