import React from 'react'
import './index.css'

export default () => {

  return (
    <div className="apply-textblock-ar-content">
      <p>{p1}</p>
      <p>{p2}</p>
      <p>{p3}</p>
      <p>{p4}</p>
      <p>{p5}</p>
      <p>{p6}</p>
      <p>{p7}</p>
      <p>{p8}</p>
      <p>{p9}</p>
      <p>{p10}</p>
      <p>{p11}</p>
    </div>
  )
}

const p1 =

  ".إن كنت هنا، فأنت تعتقد أنك أكثر الأشخاص ملاءمة لتشارك في الجيل التالي من ممثلين لبنان، لخدمة بلدنا. أهلًا وسهلًا"



const p2 =

  ".إليك البعض مما يجب أن تعرفه قبل أن تقرر متابعة العملية"




const p3 =

  ".تقديم الطلب ليس إلا الخطوة الأولى، وبعدئذٍ سيتم التواصل معك من قبل ‘المبادرة’ للتأكد من أننا قد تلقّينا كل شيء بشكل صحيح ولتتّم متابع النقاط التي قد يكون فيها اية اخطاء"



const p4 =

  ".استناداً إلى ما قدّمته وبالتنسيق معك سنبدأ بتحضير عرض للشعب اللبناني. وعليك ان تعرف أن الطلب الذي قدّمته يشكل أساساً لمقابلتك وعرضك على حد سواء وهما سيخضعان للتصويت عليهما لاحقاً في المنصة"



const p5 =

  ".حين يتّم نشر ترشيحك في قسم ‘المرشحون’ في هذا الموقع الإلكتروني، ستوضع لك نتيجة على مئة استناداً إلى ما تّم تقديمه (تقسيم النتيجة سيرد في قسم التقديم من هذه الصفحة). ستحدد النتيجة ترتيبك في الصفحة، والنتيجة الأعلى ستمنح صاحبها المركز الأعلى. كلما كانت النتيجة أعلى، كان المرشح أكثر شفافية"



const p6 =

  ".حالما بيدأ التصويت، لا تكون صاحب القرار في ما إن كنت ستظهر في هذه المنصة أو لا، وذلك يعتمد حصراً على إرادة الشعب. إن كنت مؤهلاً، فلا شك لدينا في أن الناس سيختارونك"



const p7 =

  ".عليك أن تعرف أن هذه المنصة تؤمّن لك منفعة الدعاية التي لا تُقدّر بثمن، مجاناً. ستُمنح الفرصة لجذب أعظم جيل من المواطنين اللبنانيين يوماً، وسيدينونك أو يناصرونك بالسرعة نفسها"



const p8 =

  ".هذه المنصة ليست موجودة لمساعدتك، بل لخدمة الشعب اللبناني. لا تقدّم الطلب إن لم تكن على قدر هذه الفرصة"


const p9 =
  ".يجب أن تلبَي الحد الأدنى من الشروط المطلوبة لتقديم الطلب، ويجب أن تكون قد سجلت الدخول وإن لم تفتح حساباً بعد أو إن لم تسجل الدخول بعد، فانقر هنا"

const p10 =
  ".حالما تسجل الدخول، يمكنك أن تحفظ تقدمك قبل أن تقدّم الطلب وتنهي كل شيء في أية فترة تحتاج إليها"

const p11 =
  ".نحن متحمسون لنكتشف أن خدمتنا للشعب اللبناني تخدم مصحلتك وتجعلك تكسب أصوات البعض من أعظم الناس في العالم"
