import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Navigator from './navigator';
import Home from './home';
import About from './about';
import Apply from './apply';
import Candidates from './candidates';
import Interviews from './interviews';
import Account from './account';
import Greetings from './greetings';
import Admin from './admin';
import Candidate from './candidate';
import Error from './error';

import Store from './state/store';



export default function App() {

  const [language, setLanguage] = useState('');


  return (

    <Store>
      <Router>

        {!language && <Greetings onLanguageSelected={(e) => setLanguage(e)} />}

        {language && <Navigator />}

        {language &&
          <Switch>
            <Route path="/about">
              <About />
            </Route>

            <Route path="/admin">
              <Admin />
            </Route>

            <Route path="/error">
              <Error />
            </Route>

            <Route path="/candidate">
              <Candidate />
            </Route>

            <Route path="/interviews">
              <Interviews />
            </Route>

            <Route path="/candidates">
              <Candidates />
            </Route>

            <Route path="/apply">
              <Apply />
            </Route>

            <Route path="/account">
              <Account />
            </Route>

            <Route path="/">
              <Home />
            </Route>

          </Switch>
        }
      </Router>
    </Store>
  );
}
