export default async (candidate) => {

  const errors = {};
  if (!candidate.id)
    errors["id"] = true


  if(!candidate.firstName)
      errors["firstName"] = true

  if(!candidate.lastName)
      errors["lastName"] = true

  if(!candidate.preferredEmail) {
    errors["preferredEmail"] = true
  } 

  if(!candidate.preferredPhoneNumber)
    errors["preferredPhoneNumber"] = true

  if(!candidate.dateOfBirth) {
    errors["dateOfBirth"] = true
  }



  if(!candidate.runFor) {
    errors['runFor'] = true
  };


  if(candidate.runFor === "Minister" && !candidate.runForDescription) {
    errors['runForDescription'] = true
  }



  if (!candidate.subjectedToDiscrimination)
    errors["subjectedToDiscrimination"] = true

  if (!candidate.subjectedAnyoneToDiscrimination)
    errors["subjectedAnyoneToDiscrimination"] = true

  if (!candidate.accusedOfCrime)
    errors["accusedOfCrime"] = true


  if (!candidate.convictedOfCrime)
    errors["convictedOfCrime"] = true

  if (!candidate.violatedAnyLaw)
    errors["violatedAnyLaw"] = true

  if (!candidate.subjectOfPublicScandal)
    errors["subjectOfPublicScandal"] = true

  if (!candidate.otherCitizenship)
    errors["otherCitizenship"] = true



  if (!candidate.candidateForPublicPosition)
    errors["candidateForPublicPosition"] = true

  if (!candidate.activeMemberInPoliticalParty)
    errors["activeMemberInPoliticalParty"] = true

  if (!candidate.affiliationWithPoliticalParty)
    errors["affiliationWithPoliticalParty"] = true

  if (!candidate.donatedToCampaigns)
    errors["donatedToCampaigns"] = true

  if (!candidate.involvedInBankruptcy)
    errors["involvedInBankruptcy"] = true



  if (!candidate.accussedOfTaxEvasion)
    errors["accussedOfTaxEvasion"] = true

  if (!candidate.audited)
    errors["audited"] = true

  if (!candidate.involvedInGovernmentProject)
    errors["involvedInGovernmentProject"] = true

  if (!candidate.involvedInNonGovernmentProject)
    errors["involvedInNonGovernmentProject"] = true

  if (!candidate.appointedAsConsultantByGovernment)
    errors["appointedAsConsultantByGovernment"] = true


  if (!candidate.fundedByNonGovernment)
    errors["fundedByNonGovernment"] = true

  if (!candidate.fundedByGovernment)
    errors["fundedByGovernment"] = true

  if (!candidate.bankAccountOutsideLebanon)
    errors["bankAccountOutsideLebanon"] = true

  if (!candidate.stripYourselfFromPoliticalImmunity)
    errors["stripYourselfFromPoliticalImmunity"] = true

  if (!candidate.removeYourselfFromConflictOfInterest)
    errors["removeYourselfFromConflictOfInterest"] = true













  if (candidate.subjectedToDiscrimination === 'yes' && !candidate.subjectedToDiscriminationDescription)
    errors["subjectedToDiscriminationDescription"] = true

  if (candidate.subjectedAnyoneToDiscrimination === 'yes' && !candidate.subjectedAnyoneToDiscriminationDescription)
    errors["subjectedAnyoneToDiscriminationDescription"] = true

  if (candidate.accusedOfCrime === 'yes' && !candidate.accusedOfCrimeDescription)
    errors["accusedOfCrimeDescription"] = true


  if (candidate.convictedOfCrime === 'yes' && !candidate.convictedOfCrimeDescription)
    errors["convictedOfCrimeDescription"] = true

  if (candidate.violatedAnyLaw === 'yes' && !candidate.violatedAnyLawDescription)
    errors["violatedAnyLawDescription"] = true

  if (candidate.subjectOfPublicScandal === 'yes' && !candidate.subjectOfPublicScandalDescription)
    errors["subjectOfPublicScandalDescription"] = true

  if (candidate.otherCitizenship === 'yes' && !candidate.otherCitizenshipDescription)
    errors["otherCitizenshipDescription"] = true



  if (candidate.candidateForPublicPosition === 'yes' && !candidate.candidateForPublicPositionDescription)
    errors["candidateForPublicPositionDescription"] = true

  if (candidate.activeMemberInPoliticalParty === 'yes' && !candidate.activeMemberInPoliticalPartyDescription)
    errors["activeMemberInPoliticalPartyDescription"] = true

  if (candidate.affiliationWithPoliticalParty === 'yes' && !candidate.affiliationWithPoliticalPartyDescription)
    errors["affiliationWithPoliticalPartyDescription"] = true

  if (candidate.donatedToCampaigns === 'yes' && !candidate.donatedToCampaignsDescription)
    errors["donatedToCampaignsDescription"] = true

  if (candidate.involvedInBankruptcy === 'yes' && !candidate.involvedInBankruptcyDescription)
    errors["involvedInBankruptcyDescription"] = true



  if (candidate.accussedOfTaxEvasion === 'yes' && !candidate.accussedOfTaxEvasionDescription)
    errors["accussedOfTaxEvasionDescription"] = true

  if (candidate.audited === 'yes' && !candidate.auditedDescription)
    errors["auditedDescription"] = true

  if (candidate.involvedInGovernmentProject === 'yes' && !candidate.involvedInGovernmentProjectDescription)
    errors["involvedInGovernmentProjectDescription"] = true

  if (candidate.involvedInNonGovernmentProject === 'yes' && !candidate.involvedInNonGovernmentProjectDescription)
    errors["involvedInNonGovernmentProjectDescription"] = true

  if (candidate.appointedAsConsultantByGovernment === 'yes' && !candidate.appointedAsConsultantByGovernmentDescription)
    errors["appointedAsConsultantByGovernmentDescription"] = true


  if (candidate.fundedByNonGovernment === 'yes' && !candidate.fundedByNonGovernmentDescription)
    errors["fundedByNonGovernmentDescription"] = true

  if (candidate.fundedByGovernment === 'yes' && !candidate.fundedByGovernmentDescription)
    errors["fundedByGovernmentDescription"] = true

  if (candidate.bankAccountOutsideLebanon === 'yes' && !candidate.bankAccountOutsideLebanonDescription)
    errors["bankAccountOutsideLebanonDescription"] = true

  if (candidate.stripYourselfFromPoliticalImmunity === 'no' && !candidate.stripYourselfFromPoliticalImmunityDescription)
    errors["stripYourselfFromPoliticalImmunityDescription"] = true

  if (candidate.removeYourselfFromConflictOfInterest === 'no' && !candidate.removeYourselfFromConflictOfInterestDescription)
    errors["removeYourselfFromConflictOfInterestDescription"] = true


  // where you stand
  if (!candidate.onSeparationOfReligionAndState)
    errors["onSeparationOfReligionAndState"] = true;


  if (!candidate.onLegalizingCivilMarriage)
    errors["onLegalizingCivilMarriage"] = true;


  if (!candidate.onLegalizingMarijuana)
    errors["onLegalizingMarijuana"] = true;


  if (!candidate.onLGBTQ)
    errors["onLGBTQ"] = true;


  if (!candidate.onLegalizingAbortion)
    errors["onLegalizingAbortion"] = true;


  if (!candidate.onUnifyingPersonalStatusLaws)
    errors["onUnifyingPersonalStatusLaws"] = true;


  if (!candidate.onUnifyingInheritanceLaws)
    errors["onUnifyingInheritanceLaws"] = true;


  if (!candidate.onProstitution)
    errors["onProstitution"] = true;


  if (!candidate.onFreedomOfPress)
    errors["onFreedomOfPress"] = true;


  if (!candidate.onFreedomOfSpeech)
    errors["onFreedomOfSpeech"] = true;


  if (!candidate.onIncorporationOfTechnology)
    errors["onIncorporationOfTechnology"] = true;


  if (!candidate.onWomenRights)
    errors["onWomenRights"] = true;


  if (!candidate.onChildrenRights)
    errors["onChildrenRights"] = true;


  if (!candidate.onLebaneseMothers)
    errors["onLebaneseMothers"] = true;


  if (!candidate.onStatelessPeople)
    errors["onStatelessPeople"] = true;


  if (!candidate.onPalestinianRefugees)
    errors["onPalestinianRefugees"] = true;


  if (!candidate.onGrantingSyriansRefugeeStatus)
    errors["onGrantingSyriansRefugeeStatus"] = true;


  if (!candidate.onGovernmentLootedFunds)
    errors["onGovernmentLootedFunds"] = true;


  if (!candidate.onEnvironment)
    errors["onEnvironment"] = true;


  if (!candidate.onMeritocracy)
    errors["onMeritocracy"] = true;


  if (!candidate.onTheConstitution)
    errors["onTheConstitution"] = true;


  if (!candidate.onElectingPresidentByThePeople)
    errors["onElectingPresidentByThePeople"] = true;


  if (!candidate.onPeggingLiraToUsd)
    errors["onPeggingLiraToUsd"] = true;


  if (!candidate.onLebaneseDiaspora)
    errors["onLebaneseDiaspora"] = true;


  if (!candidate.onFemaleQuota)
    errors["onFemaleQuota"] = true;


  if (!candidate.onNewElectoralLaw)
    errors["onNewElectoralLaw"] = true;


  if (!candidate.onEarlyParliamentaryElections)
    errors["onEarlyParliamentaryElections"] = true;


  if (!candidate.onTheLebaneseRevolution)
    errors["onTheLebaneseRevolution"] = true;


  if (!candidate.onLebnonAndSyria)
    errors["onLebnonAndSyria"] = true;

  if (!candidate.roleModel)
    errors["roleModel"] = true;


  return errors;

}
