import React, { useContext, useEffect, useState } from 'react'
import { Container, Button, Image, Loader } from 'semantic-ui-react'

import { CTX } from '../state/store';
import { message_types } from '../state/constants';
import './index.css'
import logo from "../assets/logo.png";
export default (props) => {

  const [appState, doAction] = useContext(CTX);
  const [loading, setLoading] = useState(true);


  function setLanguage(e) {
    localStorage.setItem('locale' , e)
    doAction({ type: message_types.SET_LANGUAGE, payload: e })
    props.onLanguageSelected(e);
  }

  useEffect(()=> {

    setLocale()
    setLoading(false);


  },[])


  async function setLocale () {
    var locale = await localStorage.getItem('locale')
    console.log(locale)
  
    await doAction({ type: message_types.SET_LANGUAGE, payload: locale })
    props.onLanguageSelected(locale);
    
  }



  if(!loading) {
    return (
      <Container>
        <div className="greetings-container">
          <Image src={logo} avatar size="tiny" />
          <p></p>
          <p>The Initiative</p>
          <div>
            <Button basic color="black" className="language-button" content="English" onClick={() => setLanguage('en')} />
            <Button basic color="black" className="language-button" content="العربية" onClick={() => setLanguage('ar')} />
          </div>

        </div>
      </Container>
    )
  } else {
    return (
       null
    )
  }
}
