import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries'



export const currentAuthenticatedUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (e) {
    console.log('could not get authenticated user', e);
    return null;
  }
}

export const getIdentityId = async () => {
  try {
    return (await Auth.currentCredentials()).params.IdentityId;
  } catch (e) {
    console.log('could not get identity', e);
    return {};
  }
}

/**
 * Candidates
 */
export const getCandidate = async (owner) => {
  try {
    const candidate = await API.graphql(graphqlOperation(queries.getCandidate, { owner }));
    return candidate.data.getCandidate;
  } catch (e) {
    console.log('could not get candidate', e)
    return null;
  }
}

export const createCandidate = async (input) => {
  try {
    const candidate = await API.graphql(graphqlOperation(mutations.createCandidate, { input }));
    return candidate;
  } catch (e) {
    console.log('could not create candidate', e);
    throw e;
  }
}

export const updateCandidate = async (input) => {

  try {
    const candidate = await API.graphql(graphqlOperation(mutations.updateCandidate, {input}));
    return candidate;
  } catch (e) {
    console.log('could not update candidate', e);
    throw e;
  }
}

export const listCandidates = async (filter, limit, nextToken) => {
  try {
    const candidates = await API.graphql(graphqlOperation(queries.listCandidates, { filter, limit, nextToken }));
    return candidates.data.listCandidates;
  } catch (e) {
    console.log('could not list candidates', e);
    return null;
  }
}

export const deleteCandidate = async (owner) => {

  try {
    const candidate = await API.graphql(graphqlOperation(mutations.deleteCandidate, { input: { owner } }));
    return candidate;
  } catch (e) {
    console.log('could not delete candidate', e)
    return null;
  }
}


/**
 * S3
 */

export const getObject = async (id, level, userInfo) => {
  try {
    const storageConfig = {}
    if (level)
      storageConfig.level = level;

    if (userInfo)
      if (userInfo.identityId) {
        // console.log('identityId', userInfo.identityId)
        storageConfig.identityId = userInfo.identityId;
      }

    return await Storage.get(id, storageConfig);
  } catch (e) {
    console.log('could not get file', e)
    return null;
  }
}

// add file to s3 bucket
export const putObject = async (key, value, level, contentType) => {

  try {
    const storageConfig = {}
    if (level)
      storageConfig.level = level;

    if (contentType)
      storageConfig.contentType = contentType;

    const result = await Storage.put(key, value, storageConfig).catch();
    return result;
  } catch (e) {
    console.log('could not upload file', e)
    return null;
  }
}

// remove file from s3 bucket
export const removeObject = async (key, level) => {
  try {
    const storageConfig = {}
    if (level)
      storageConfig.level = level;

    return await Storage.remove(key, storageConfig);
  } catch (e) {
    console.log('could not remove file', e)
    return null;
  }
}



// list file from s3 bucket, path and userInfo
export const listObjects = async (key, level, userInfo) => {
  try {
    const storageConfig = {}
    if (level)
      storageConfig.level = level;

    if (userInfo)
      storageConfig.identityId = userInfo;
    return await Storage.list(key, storageConfig)
  } catch (e) {
    console.log('could not list files', e)
    return null;
  }
}


