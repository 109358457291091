import React, { useEffect, useContext } from 'react'
import { Authenticator, SignUp, SignIn, AuthPiece } from 'aws-amplify-react';
import CustomSignUp from './customSignUp';
import CustomSignIn from './customSignIn';
import './index.css'

import { CTX } from '../state/store';
import { useLocation } from "react-router-dom";
import { message_types } from '../state/constants';

export default () => {

  const [, doAction] = useContext(CTX);
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname.substr(1);
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  const updateUser = (user) => {
    doAction({ type: message_types.SET_USER, payload: user })
  }

  return (
    <Authenticator hide={[SignUp, SignIn]}>
      <CustomSignIn updateUser={updateUser} override={'SignIn'} />
      <CustomSignUp override={'SignUp'} />
      <Content />
    </Authenticator>
  )
}

class Content extends React.Component {

  render() {
    if (this.props.authState === "signedIn") {
      return (
        <div className="account-container">
          <h1>Welcome!</h1>
        </div>
      );
    } else {
      return null;
    }
  }
}
