import React from 'react'
import './index.css'
import { Divider } from 'semantic-ui-react'

export default () => {


  const p2 = "مصممة لتقدّم إلى الشعب اللبناني طريقة تقييم صلبة وحيادية لجميع المرشحين الذين يختارون المشاركة في هذه المنصة والتقيد بقوانين أهليتها ويتّم اختيارهم للظهور في المنصّة وفقاً لتصويت تجريه هذه الاخيرة عبر الالية المعتمدة"

  const p3 = "تملك بالتالي القدرة على تمكين أي مرشح يسعى لامتياز خدمة الشعب اللبناني في الحكم، وبشرعية صوت الشعب اللبناني، إن كسب رضا الشعب اللبناني بعد المشاركة في المنصة"

  const p4 = "لن تكون منحازة أبداً ولن تعتمد المحاباة أبداً ولن تؤيد أو توصي أو تنصح بأي مرشح ليدعمه أي مواطن لبناني أو جماعة لبنانية"

  const p5 = "تعمل على مبدأ وحيد ألا وهو الإيمان بالشعب اللبناني وقدرته على أن يقرر، بقوة التصويت، مَن الأكثر تمثيلاً لمصالحه. ان الإيمان بالشعب البناني هو الإيمان بصواب حكمه، وهدف ‘المبادرة’ هو المساعدة على  اطلاع الشعب اللبناني على كل ما يستطيع بواسطته اتخاذ القرارات الصائبة لاختيار ممثليه الجدد"

  const p6 = "ستتمثّل بمتحدث واحد باسمها، وسيكون الشخص نفسه الذي يجري المقابلات. وستكون في البداية ممثّلة بشخص مؤسسها نمر أبو نصار، لكنها صُممت بطريقة تسمح لها بأن تخدم الشعب اللبناني بطريقة فعالة، مع مشاركة مؤسسها أو بدونه"

  const p7 = "مؤلفة من الكثير من المتطوعين البنانيين من جميع الخلفيات، وقد أشرِكوا حصراً على أساس مؤهلاتهم لضمان تمثيل متنوع للشعب اللبناني، بدون أية محاباة، وبذلك ضمان حيادية المنصة"

  const p8 = "ستكون دائماً في خدمة الشعب اللبناني"

  return (
    <div className="home-textblock-ar-container">
      <p><b>المبادرة</b> {p2}</p>
      <Divider />
      <p><b>المبادرة</b> {p3}</p>
      <Divider />
      <p><b>المبادرة</b> {p4}</p>
      <Divider />
      <p><b>المبادرة</b> {p5}</p>
      <Divider />
      <p><b>المبادرة</b> {p6}</p>
      <Divider />
      <p><b>المبادرة</b> {p7}</p>
      <Divider />
      <p><b>المبادرة</b> {p8}</p>
    </div>
  )
}

