import React from 'react'
import { Container, Icon } from 'semantic-ui-react'

import './index.css'

export default () => {

  return (
    <Container>
      <div className="error-container">

        <Icon size="huge" color="orange" name="lock" />
        <h1>Cannot view page</h1>

      </div>
    </Container>
  )
}
