/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCandidate = `query GetCandidate($owner: String!) {
  getCandidate(owner: $owner) {
    id
    owner
    status
    identityId
    subjectedToDiscrimination
    subjectedToDiscriminationDescription
    subjectedAnyoneToDiscrimination
    subjectedAnyoneToDiscriminationDescription
    accusedOfCrime
    accusedOfCrimeDescription
    convictedOfCrime
    convictedOfCrimeDescription
    violatedAnyLaw
    violatedAnyLawDescription
    subjectOfPublicScandal
    subjectOfPublicScandalDescription
    otherCitizenship
    otherCitizenshipDescription
    candidateForPublicPosition
    candidateForPublicPositionDescription
    activeMemberInPoliticalParty
    activeMemberInPoliticalPartyDescription
    affiliationWithPoliticalParty
    affiliationWithPoliticalPartyDescription
    donatedToCampaigns
    donatedToCampaignsDescription
    involvedInBankruptcy
    involvedInBankruptcyDescription
    accussedOfTaxEvasion
    accussedOfTaxEvasionDescription
    audited
    auditedDescription
    involvedInGovernmentProject
    involvedInGovernmentProjectDescription
    involvedInNonGovernmentProject
    involvedInNonGovernmentProjectDescription
    appointedAsConsultantByGovernment
    appointedAsConsultantByGovernmentDescription
    fundedByNonGovernment
    fundedByNonGovernmentDescription
    fundedByGovernment
    fundedByGovernmentDescription
    bankAccountOutsideLebanon
    bankAccountOutsideLebanonDescription
    stripYourselfFromPoliticalImmunity
    stripYourselfFromPoliticalImmunityDescription
    removeYourselfFromConflictOfInterest
    removeYourselfFromConflictOfInterestDescription
    onSeparationOfReligionAndState
    onSeparationOfReligionAndStateDescription
    onLegalizingCivilMarriage
    onLegalizingCivilMarriageDescription
    onLegalizingMarijuana
    onLegalizingMarijuanaDescription
    onLGBTQ
    onLGBTQDescription
    onLegalizingAbortion
    onLegalizingAbortionDescription
    onUnifyingPersonalStatusLaws
    onUnifyingPersonalStatusLawsDescription
    onUnifyingInheritanceLaws
    onUnifyingInheritanceLawsDescription
    onProstitution
    onProstitutionDescription
    onFreedomOfPress
    onFreedomOfPressDescription
    onFreedomOfSpeech
    onFreedomOfSpeechDescription
    onIncorporationOfTechnology
    onIncorporationOfTechnologyDescription
    onWomenRights
    onWomenRightsDescription
    onChildrenRights
    onChildrenRightsDescription
    onLebaneseMothers
    onLebaneseMothersDescription
    onStatelessPeople
    onStatelessPeopleDescription
    onPalestinianRefugees
    onPalestinianRefugeesDescription
    onGrantingSyriansRefugeeStatus
    onGrantingSyriansRefugeeStatusDescription
    onGovernmentLootedFunds
    onGovernmentLootedFundsDescription
    onEnvironment
    onEnvironmentDescription
    onMeritocracy
    onMeritocracyDescription
    onTheConstitution
    onTheConstitutionDescription
    onElectingPresidentByThePeople
    onElectingPresidentByThePeopleDescription
    onPeggingLiraToUsd
    onPeggingLiraToUsdDescription
    onLebaneseDiaspora
    onLebaneseDiasporaDescription
    onFemaleQuota
    onFemaleQuotaDescription
    onNewElectoralLaw
    onNewElectoralLawDescription
    onEarlyParliamentaryElections
    onEarlyParliamentaryElectionsDescription
    onTheLebaneseRevolution
    onTheLebaneseRevolutionDescription
    onLebnonAndSyria
    onLebnonAndSyriaDescription
    roleModel
    dateOfBirth
    firstName
    lastName
    preferredEmail
    preferredPhoneNumber
    runFor
    runForDescription
    adminComment
  }
}
`;
export const listCandidates = `query ListCandidates(
  $owner: String
  $filter: ModelCandidateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCandidates(
    owner: $owner
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      status
      identityId
      subjectedToDiscrimination
      subjectedToDiscriminationDescription
      subjectedAnyoneToDiscrimination
      subjectedAnyoneToDiscriminationDescription
      accusedOfCrime
      accusedOfCrimeDescription
      convictedOfCrime
      convictedOfCrimeDescription
      violatedAnyLaw
      violatedAnyLawDescription
      subjectOfPublicScandal
      subjectOfPublicScandalDescription
      otherCitizenship
      otherCitizenshipDescription
      candidateForPublicPosition
      candidateForPublicPositionDescription
      activeMemberInPoliticalParty
      activeMemberInPoliticalPartyDescription
      affiliationWithPoliticalParty
      affiliationWithPoliticalPartyDescription
      donatedToCampaigns
      donatedToCampaignsDescription
      involvedInBankruptcy
      involvedInBankruptcyDescription
      accussedOfTaxEvasion
      accussedOfTaxEvasionDescription
      audited
      auditedDescription
      involvedInGovernmentProject
      involvedInGovernmentProjectDescription
      involvedInNonGovernmentProject
      involvedInNonGovernmentProjectDescription
      appointedAsConsultantByGovernment
      appointedAsConsultantByGovernmentDescription
      fundedByNonGovernment
      fundedByNonGovernmentDescription
      fundedByGovernment
      fundedByGovernmentDescription
      bankAccountOutsideLebanon
      bankAccountOutsideLebanonDescription
      stripYourselfFromPoliticalImmunity
      stripYourselfFromPoliticalImmunityDescription
      removeYourselfFromConflictOfInterest
      removeYourselfFromConflictOfInterestDescription
      onSeparationOfReligionAndState
      onSeparationOfReligionAndStateDescription
      onLegalizingCivilMarriage
      onLegalizingCivilMarriageDescription
      onLegalizingMarijuana
      onLegalizingMarijuanaDescription
      onLGBTQ
      onLGBTQDescription
      onLegalizingAbortion
      onLegalizingAbortionDescription
      onUnifyingPersonalStatusLaws
      onUnifyingPersonalStatusLawsDescription
      onUnifyingInheritanceLaws
      onUnifyingInheritanceLawsDescription
      onProstitution
      onProstitutionDescription
      onFreedomOfPress
      onFreedomOfPressDescription
      onFreedomOfSpeech
      onFreedomOfSpeechDescription
      onIncorporationOfTechnology
      onIncorporationOfTechnologyDescription
      onWomenRights
      onWomenRightsDescription
      onChildrenRights
      onChildrenRightsDescription
      onLebaneseMothers
      onLebaneseMothersDescription
      onStatelessPeople
      onStatelessPeopleDescription
      onPalestinianRefugees
      onPalestinianRefugeesDescription
      onGrantingSyriansRefugeeStatus
      onGrantingSyriansRefugeeStatusDescription
      onGovernmentLootedFunds
      onGovernmentLootedFundsDescription
      onEnvironment
      onEnvironmentDescription
      onMeritocracy
      onMeritocracyDescription
      onTheConstitution
      onTheConstitutionDescription
      onElectingPresidentByThePeople
      onElectingPresidentByThePeopleDescription
      onPeggingLiraToUsd
      onPeggingLiraToUsdDescription
      onLebaneseDiaspora
      onLebaneseDiasporaDescription
      onFemaleQuota
      onFemaleQuotaDescription
      onNewElectoralLaw
      onNewElectoralLawDescription
      onEarlyParliamentaryElections
      onEarlyParliamentaryElectionsDescription
      onTheLebaneseRevolution
      onTheLebaneseRevolutionDescription
      onLebnonAndSyria
      onLebnonAndSyriaDescription
      roleModel
      dateOfBirth
      firstName
      lastName
      preferredEmail
      preferredPhoneNumber
      runFor
      runForDescription
      adminComment
    }
    nextToken
  }
}
`;
