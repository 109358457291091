import React, { useContext, useState, useEffect } from 'react'
import { Menu, Button, Icon, Divider, Dropdown } from 'semantic-ui-react'
import { useHistory, useLocation } from "react-router-dom";

import * as api from '../api';
import { CTX } from '../state/store';
import { pages, message_types } from '../state/constants';
import './index.css'


export default function App() {

  let history = useHistory();
  let location = useLocation();

  const [appState, doAction] = useContext(CTX);
  const [isMobile, setIsMobile] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  function handleItemClick(page) {

    history.push("/" + page)
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })

    setIsMenuVisible(false);
  }

  function sizeChanged() {
    setIsMobile(window.innerWidth < 768);
  }

  useEffect(() => {

    sizeChanged();
    window.addEventListener("resize", sizeChanged);
    
    function removeWindowListener () {
      window.removeEventListener("resize", sizeChanged);

    }

    return () => {
      removeWindowListener()
    }

  }, []);


  



  useEffect(() => {
    api.currentAuthenticatedUser().then((res) => {

      doAction({ type: message_types.SET_USER, payload: res })
      const page = location.pathname.substr(1);

      // if page is admin and user is not signed in or no admin permissions
      if (page === pages.ADMIN
        && (!res
          || !res.signInUserSession.idToken.payload["cognito:groups"]
          || !res.signInUserSession.idToken.payload["cognito:groups"].includes('Administrators'))) {

        history.push("/" + pages.HOME)
        doAction({ type: message_types.SET_SELECT_PAGE, payload: pages.HOME })

      } else {

        doAction({ type: message_types.SET_SELECT_PAGE, payload: page })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  async function setLocale (e,data) {
    await localStorage.setItem('locale' ,data.value)

    // doAction({ type: message_types.SET_LANGUAGE, payload: data.value })            
    await doAction({ type: message_types.SET_LANGUAGE, payload: data.value})
    setIsMenuVisible(false);     
  }




  return (


    <nav>

      {isMobile &&
        <Button icon className="navigator-menu-icon" onClick={() => setIsMenuVisible(!isMenuVisible)}>
          <Icon size="big" name='bars' />
        </Button>
      }

      <h1 className="navigator-title">The Initiative</h1>


      {isMobile &&
        <Divider />
      }

      {
        (!isMobile || (isMobile && isMenuVisible)) &&
        <Menu stackable pointing secondary className="navigator-menu">


          <Menu.Menu position='left'>


            <a target="_blank" rel="noopener noreferrer" href="mailto:contact@initiativelb.com">
              <Menu.Item
                name={appState.language === 'ar' ? 'اتصل بنا' : 'contact'}
              />
            </a>


            <Menu.Item>
              <Dropdown
                inline
                value={appState.language}
                button
                floating
                item
                labeled
                options={languageOptions}
                search
                onChange={setLocale}
              />
            </Menu.Item>
          </Menu.Menu>


          <Menu.Menu position='right'>


            <Menu.Item
              name={appState.language === 'ar' ? 'الصفحة الأساسية' : 'home'}
              active={appState.selected_page === pages.HOME}
              onClick={() => {
                handleItemClick(pages.HOME)
              }}
            />


            <Menu.Item
              name={appState.language === 'ar' ? 'تقديم الطلب' : 'apply'}
              active={appState.selected_page === pages.APPLY}
              onClick={() => {
                handleItemClick(pages.APPLY)
              }}
            />

            <Menu.Item
              name={appState.language === 'ar' ? 'المرشحون' : 'candidates'}
              active={appState.selected_page === pages.CANDIDATES}
              onClick={() => {
                handleItemClick(pages.CANDIDATES)
              }}
            />

            <Menu.Item
              name={appState.language === 'ar' ? 'المقابلات' : 'interviews'}
              active={appState.selected_page === pages.INTERVIEWS}
              onClick={() => {
                handleItemClick(pages.INTERVIEWS)
              }}
            />


            {appState.groups && appState.groups.includes('Administrators') &&
              <Menu.Item
                name={'admin'}
                active={appState.selected_page === pages.ADMIN}
                onClick={() => {
                  handleItemClick(pages.ADMIN)
                }}
              />}



            <Button color="teal" size="small"
              onClick={() => handleItemClick(pages.ACCOUNT)}>
              {appState.language === 'ar' ? 'الحساب' : 'Account'}

            </Button>


          </Menu.Menu>
        </Menu>
      }

    </nav >


  );
}


const languageOptions = [
  { key: 'ar', text: 'العربية', value: 'ar' },
  { key: 'en', text: 'English', value: 'en' }
];
