import React, { useState, useEffect, useContext, useRef } from 'react'
import {  Progress, Button, Container, Label, Form, Input, Radio, Divider, Message, Icon } from 'semantic-ui-react'
import { SignIn, Authenticator, SignUp, Greetings } from 'aws-amplify-react';

import {Auth , Storage} from 'aws-amplify'

import TextBlock from './textblock';
import TextBlockArabic from './textblock-ar';
import * as api from '../api';
import './index.css'
import isValidCandidate from './isValidCandidate';
import { CTX } from '../state/store';
import { message_types } from '../state/constants';

import labels from './constants';
import CustomSignIn from '../account/customSignIn';
import CustomSignUp from '../account/customSignUp';


// function b64toBlob(b64Data, contentType) {
//         contentType = contentType || 'image/jpeg';
//         var sliceSize = 512;
    
//         var byteCharacters = atob(b64Data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
//         var byteArrays = [];
    
//         for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//             var slice = byteCharacters.slice(offset, offset + sliceSize);
    
//             var byteNumbers = new Array(slice.length);
//             for (var i = 0; i < slice.length; i++) {
//                 byteNumbers[i] = slice.charCodeAt(i);
//             }
    
//             var byteArray = new Uint8Array(byteNumbers);
    
//             byteArrays.push(byteArray);
//         }
    
//         var blob = new Blob(byteArrays, {type: contentType});
//         return blob;
// }


function checkSize(file) {
    return new Promise((resolve, reject)=> {
      var reader = new FileReader(); // CREATE AN NEW INSTANCE.

      reader.onload = function (e) {
            var img = new Image();      
            img.src = e.target.result;

            img.onload = async  function () {
              var w = await this.width;
              var h = await this.height;
              var allowance = 50;

              if (Math.abs(w-1048) > allowance || Math.abs(h-1048) > allowance ) {

                resolve(false);
              } else {
                resolve(true);
              }

              // var canvas = document.createElement('canvas');
              // var ctx = canvas.getContext("2d");
              
              // ctx.drawImage(img, 0, 0);
              // canvas.width =1024;
              // canvas.height = 1024;
              // ctx.drawImage(img, 0, 0, 1024, 1024);
              // var dataURL = canvas.toDataURL("image/jpeg");
              //  var blob =b64toBlob(dataURL, 'image/jpeg');
              // resolve(blob)

       
            }
        };

        reader.readAsDataURL(file);

        

    })
        
}



const Application = (props) => {

  const defaultCandidate = {
    id: '',

    firstName: '',
    lastName: '',
    preferredEmail: '',
    preferredPhoneNumber: '',
    dateOfBirth: '',
    status: "SAVED",

    runFor: "",
    runForDescription: "",


    subjectedToDiscrimination: '',
    subjectedToDiscriminationDescription: '',

    subjectedAnyoneToDiscrimination: '',
    subjectedAnyoneToDiscriminationDescription: '',

    accusedOfCrime: '',
    accusedOfCrimeDescription: '',

    convictedOfCrime: '',
    convictedOfCrimeDescription: '',

    violatedAnyLaw: '',
    violatedAnyLawDescription: '',

    subjectOfPublicScandal: '',
    subjectOfPublicScandalDescription: '',

    otherCitizenship: '',
    otherCitizenshipDescription: '',

    candidateForPublicPosition: '',
    candidateForPublicPositionDescription: '',

    activeMemberInPoliticalParty: '',
    activeMemberInPoliticalPartyDescription: '',

    affiliationWithPoliticalParty: '',
    affiliationWithPoliticalPartyDescription: '',

    donatedToCampaigns: '',
    donatedToCampaignsDescription: '',

    involvedInBankruptcy: '',
    involvedInBankruptcyDescription: '',

    accussedOfTaxEvasion: '',
    accussedOfTaxEvasionDescription: '',

    audited: '',
    auditedDescription: '',

    involvedInGovernmentProject: '',
    involvedInGovernmentProjectDescription: '',

    involvedInNonGovernmentProject: '',
    involvedInNonGovernmentProjectDescription: '',

    appointedAsConsultantByGovernment: '',
    appointedAsConsultantByGovernmentDescription: '',

    fundedByNonGovernment: '',
    fundedByNonGovernmentDescription: '',

    fundedByGovernment: '',
    fundedByGovernmentDescription: '',

    bankAccountOutsideLebanon: '',
    bankAccountOutsideLebanonDescription: '',

    stripYourselfFromPoliticalImmunity: '',
    stripYourselfFromPoliticalImmunityDescription: '',

    removeYourselfFromConflictOfInterest: '',
    removeYourselfFromConflictOfInterestDescription: '',


    // where you stand
    onSeparationOfReligionAndState: '',
    onSeparationOfReligionAndStateDescription: '',

    onLegalizingCivilMarriage: '',
    onLegalizingCivilMarriageDescription: '',

    onLegalizingMarijuana: '',
    onLegalizingMarijuanaDescription: '',

    onLGBTQ: '',
    onLGBTQDescription: '',

    onLegalizingAbortion: '',
    onLegalizingAbortionDescription: '',

    onUnifyingPersonalStatusLaws: '',
    onUnifyingPersonalStatusLawsDescription: '',

    onUnifyingInheritanceLaws: '',
    onUnifyingInheritanceLawsDescription: '',

    onProstitution: '',
    onProstitutionDescription: '',

    onFreedomOfPress: '',
    onFreedomOfPressDescription: '',

    onFreedomOfSpeech: '',
    onFreedomOfSpeechDescription: '',

    onIncorporationOfTechnology: '',
    onIncorporationOfTechnologyDescription: '',

    onWomenRights: '',
    onWomenRightsDescription: '',

    onChildrenRights: '',
    onChildrenRightsDescription: '',

    onLebaneseMothers: '',
    onLebaneseMothersDescription: '',

    onStatelessPeople: '',
    onStatelessPeopleDescription: '',

    onPalestinianRefugees: '',
    onPalestinianRefugeesDescription: '',

    onGrantingSyriansRefugeeStatus: '',
    onGrantingSyriansRefugeeStatusDescription: '',

    onGovernmentLootedFunds: '',
    onGovernmentLootedFundsDescription: '',

    onEnvironment: '',
    onEnvironmentDescription: '',

    onMeritocracy: '',
    onMeritocracyDescription: '',

    onTheConstitution: '',
    onTheConstitutionDescription: '',

    onElectingPresidentByThePeople: '',
    onElectingPresidentByThePeopleDescription: '',

    onPeggingLiraToUsd: '',
    onPeggingLiraToUsdDescription: '',

    onLebaneseDiaspora: '',
    onLebaneseDiasporaDescription: '',

    onFemaleQuota: '',
    onFemaleQuotaDescription: '',

    onNewElectoralLaw: '',
    onNewElectoralLawDescription: '',

    onEarlyParliamentaryElections: '',
    onEarlyParliamentaryElectionsDescription: '',

    onTheLebaneseRevolution: '',
    onTheLebaneseRevolutionDescription: '',

    onLebnonAndSyria: '',
    onLebnonAndSyriaDescription: '',

    roleModel: ''
  }

 

  const [candidate, setCandidate] = useState(defaultCandidate);
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [appState,] = useContext(CTX);
  const [page, setPage] = useState(1);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    profilePicture: 0,
    whyvote: 0,
    criminalrecord: 0,
    pledge: 0,
  })
  const [submitSuccessful, setSubmitSuccessful]= useState();
  const [candidateHasSaved, setCandidateHasSaved] = useState(false);

  const [profilePictureSizeError , setProfilePictureSizeError] = useState();


  useEffect(() => {
    if(appState.user) {
      if (props.candidate) {
        loadSavedApplication()
      } else {
        getUserInfo()
      }
    } 
  
    // eslint-disable-next-line
  }, [appState.user])



  useEffect(()=> {
    if(submitSuccessful) {
      props.onSubmit()
    } 
  },[submitSuccessful])


  async function chunkSplit (data, chunksize) {
    return new Promise(async (resolve,reject) => {
      var values = Object.values(data);
      var final = [];
      var counter = 0;
      var portion = {};
      for (var key in data) {
        if (counter !== 0 && counter % chunksize === 0) {
          final.push(portion);
          portion = {};
        }
        portion[key] = values[counter];
        counter++
      }
      await final.push(portion); 
      resolve(final)
    })
  }

  async function getUserInfo() {

    //  Defaults phone number and email to cognito values,
    //  however, for edit page this must be different
    // Found Bug here as component rendered before signing in if user was not signed up.
    const info = await Auth.currentUserInfo();
    const attributes = info.attributes;
    console.log("Bootstrapped");
    setCandidate({ ...defaultCandidate, 
      id: appState.user,
      owner: appState.user,
      preferredPhoneNumber: attributes.phone_number,
      preferredEmail: attributes.email 
    })  

  }

  async function loadSavedApplication () {

    setCandidate(props.candidate);
    setCandidateHasSaved(true);
    const identity = await api.getIdentityId()
    const objects = await api.listObjects('' , 'protected' , identity );
    setFiles((files) => {
      const newState = {
        ...files,

      };
        objects.forEach(async(el)=> {
          newState[el.key] = true;
        })
      return newState
    })
    setUploadProgress((uploadProgress) => {
      const newState = {
        ...uploadProgress,

      };
        objects.forEach(async(el)=> {
          newState[el.key] = 100;
        })
      return newState
    })
    setErrors((errors) => {
      const newState = {
        ...errors,

      };
        objects.forEach(async(el)=> {
          newState[el.key] = false;
        })
      return newState
    })

  }

  function handleChange(e) {
    setCandidate({ ...candidate, [e.target.id]: e.target.value })

  }

  function handleRadioChange(e, { value }) {
    setCandidate({ ...candidate, [e.target.name]: value })
  }

  async function handleFileChange(e) {
    let key = e.target.id;
    const file = e.target.files[0];

    // setUploadProgress({...uploadProgress, [key]: 0})
    setUploadProgress((prevProgress)=> {
      return {...prevProgress, [key]: 0}
    })
    setFiles((prevFiles) => {
      return {...prevFiles, [key]: false}
    });

    if (!file) {
      console.log('no file chosen');
      return
    }
    if (key === "profilePicture") {
      const rightSize = await checkSize(file)
      
      if (!rightSize) {
        setProfilePictureSizeError(true);
        return;
      } else {
        setProfilePictureSizeError(false);
      }
    }
    try {
      const storageConfig = {
        contentType: file.fileType,
        progressCallback(progress) {
          setUploadProgress((prevProgress) => {
            return {...prevProgress, [key]: progress.loaded*100/progress.total}
          })
          if(progress.loaded === progress.total) {
            setFiles((prevFiles) => {
              return {...prevFiles, [key]: true}
            });
            setErrors({ ...errors, [key]: false })
          }
        }
      }

      storageConfig.level = 'protected'

      await Storage.put(key, file, storageConfig);

    } catch (err) {
      console.log(err)
    }


  
    // setErrors({ ...errors, [key]: false });

  }


  async function submit() {

    setIsLoading(true);

    const newCandidate = {
      id: candidate.id,
      owner: candidate.owner
    };

    let _errors = await isValidCandidate(candidate);



    if (!files['profilePicture'])
      _errors['profilePicture'] = true
    if (!files['whyvote'])
      _errors['whyvote'] = true
    if (!files['pledge'])
      _errors['pledge'] = true
    if (!files['criminalrecord'])
      _errors['criminalrecord'] = true

    setErrors(_errors);




    if (Object.keys(_errors).length > 0 && Object.values(_errors).includes(true)) {

      setIsLoading(false);
      return;
    }
    

    for (var property in candidate) {
      if (candidate.hasOwnProperty(property) && candidate[property]) {
        newCandidate[property] = candidate[property]
      }
    }


    const identityId = await api.getIdentityId();
    newCandidate['identityId'] = identityId;
    newCandidate['status'] = "SUBMITTED";
    
   
    if(props.candidate || candidateHasSaved) {
      try {
        const data = await chunkSplit(newCandidate, 20);
        const promises = data.map(async (chunk) => {
          chunk.id = candidate.id;
          chunk.owner = candidate.owner;
          await api.updateCandidate(chunk); 
        })

        await Promise.all(promises);
        
        
      } catch(err) {
        setErrors({ ...errors, "submit": true })
        setSubmitSuccessful(false);
        console.log(err)
      }
    } else {
      try {
        await api.createCandidate(newCandidate);
      } catch(err) {
        setSubmitSuccessful(false);
        setErrors({ ...errors, "submit": true })
        console.log(err)
      }

    }
    setSubmitSuccessful(true);
    setIsLoading(false);
    
     

  }

  function handleRunForChange(e, { value }) {
    if(value === "Parliament") {
      setCandidate({...candidate, runFor: value, runForDescription: ""})
    } else if (value === "Minister") {
      setCandidate({...candidate, runFor: value})
    } 
  }



  async function save() {
  
    setIsSaveLoading(true);
    const newCandidate = {};


    for (var property in candidate) {
      if (candidate.hasOwnProperty(property) && candidate[property]) {
        newCandidate[property] = await candidate[property]
      }
    }

    const identityId = await api.getIdentityId();
    newCandidate['identityId'] = identityId;
    newCandidate['status'] = "SAVED";


    if(props.candidate || candidateHasSaved) {
      try {
        const data = await chunkSplit(newCandidate, 20);

        const promises = data.map(async (chunk) => {
          chunk.id = candidate.id;
          chunk.owner = candidate.owner;
          await api.updateCandidate(chunk); 
        })

        await Promise.all(promises);
        
      } catch(err) {
        console.log(err)
      }
    } else {
      try {
        await api.createCandidate(newCandidate);
    

      } catch(err) {
        console.log(err)
      }
    }
    setCandidateHasSaved(true)
    setIsSaveLoading(false);

  }


  
  const startOfPage = useRef(null)

  async function changePage (page) {
      setPage(page);
      window.scrollTo(100, startOfPage.current.offsetTop);
  }


  return (
    <Container className={"application-body-" + appState.language}>

      {props.authState === "signedIn"  &&
       
        <div className="application-container">
               <Button loading={isSaveLoading} disabled={isSaveLoading} className={"floatingSave-"+ appState.language} color="teal" onClick={save}>{labels[appState.language].save}</Button>

          

          <div className="titleQuestions">
            <h4 className={appState.language === "ar"? "arabicText": null}>{labels[appState.language].titleQuestions}</h4>

          </div>

          <br/>
          <br/>


          <div className={"application-inner-" + appState.language}>
            <Form>

              



              <h2> {labels[appState.language].documents} </h2>

              <Divider />

              {appState.language === "en" && <TextBlock />}
              {appState.language === "ar" && <TextBlockArabic />}

              <Divider/>
              <br/>

              <div ref={startOfPage}></div>

            {page === 1 && 
            <>
             <b> <p>{labels[appState.language].publicProfile}</p> </b>
              <Form.Field required className="form-field">
                <label>{labels[appState.language].firstName} </label>
                {errors["firstName"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="firstName"
                  placeholder={labels[appState.language].firstName}
                  value={candidate['firstName']}
                  onChange={handleChange} />
              </Form.Field>


               <Form.Field required className="form-field">
                <label>{labels[appState.language].lastName} </label>
                {errors["lastName"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="lastName"
                  placeholder={labels[appState.language].lastName}
                  value={candidate['lastName']}
                  onChange={handleChange} />
              </Form.Field>

               <Form.Field required className="form-field">
                <label>{labels[appState.language].preferredEmail} </label>
                {errors["preferredEmail"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                {errors["preferredEmailFormat"] && <Label basic color='red' pointing='below'>{labels[appState.language].preferredEmailFormat}</Label>}
                <Input
                  id="preferredEmail"
                  type="email"
                  placeholder={labels[appState.language].preferredEmail}
                  value={candidate['preferredEmail']}
                  onChange={handleChange} />
              </Form.Field>
              <Form.Field required className="form-field">
                <label>{labels[appState.language].preferredPhoneNumber} </label>
                {errors["preferredPhoneNumber"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                {errors["preferredPhoneNumberFormat"] && <Label basic color='red' pointing='below'>{labels[appState.language].preferredPhoneNumberFormat}</Label>}
                <Input
                  id="preferredPhoneNumber"
                  type="phone"
                  placeholder={labels[appState.language].preferredPhoneNumber}
                  value={candidate['preferredPhoneNumber']}
                  onChange={handleChange} />
              </Form.Field>

              <Form.Field required className="form-field">
                <label>{labels[appState.language].dateOfBirth} </label>
                {errors["dateOfBirth"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="dateOfBirth"
                  value={candidate['dateOfBirth']}
                  onChange={handleChange} />
              </Form.Field>


              <Form.Field required className="form-field">
                <label>{labels[appState.language].profilePicture} (size 1024x1024 px) </label>
                {errors["profilePicture"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectfile}</Label>}
                {profilePictureSizeError && <Label basic color='red' pointing='below'>{labels[appState.language].wrongProfilePictureSize}</Label>}
                <Input id="profilePicture" type="file" onChange={handleFileChange}/> 
                <br/>
                <br/>

                {uploadProgress.profilePicture?
                <Progress success={uploadProgress.profilePicture === 100? true : false} percent={uploadProgress.profilePicture} indicating>
                  {uploadProgress.profilePicture === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                  </Progress>
                  :

                  null
                }

                <br/>
          

              </Form.Field>

              <Divider/>
        
            {errors["runFor"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
            <Form.Field required>

            <label> {labels[appState.language].runFor }</label>
          
              <Radio
                label={labels[appState.language].parliament }
                name='radioGroup'
                className="radio"
                value='Parliament'
                checked={candidate.runFor === 'Parliament'}
                onChange={handleRunForChange}
              />
              <Radio
              
                label={labels[appState.language].minister }
                name='runAs'
                className="radio"
                value='Minister'
                checked={candidate.runFor === 'Minister'}
                onChange={handleRunForChange}
              />
            </Form.Field>

              <Form.Field required className="empty-form form-field">
                { candidate.runFor === "Minister"?
                <>
                  <label> {labels[appState.language].runForDescription }</label>
                  {errors["runForDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                  <Input
                    id="runForDescription"
                    value={candidate['runForDescription']}
                    onChange={handleChange} />
                </>

                :null

                }

              </Form.Field>



            
            

              <Form.Field required className="form-field">
                <label>{labels[appState.language].d1}</label>

                <p>{labels[appState.language].d11}</p>
                <p>{labels[appState.language].d12}</p>
                <p>{labels[appState.language].d13}</p>
                <p>{labels[appState.language].d14}</p>

                {errors["whyvote"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectfile}</Label>}
                <Input id="whyvote" name="whyvote" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.whyvote?
                <Progress success={uploadProgress.whyvote === 100? true : false} percent={uploadProgress.whyvote} indicating>
                  {uploadProgress.whyvote === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                  </Progress>
                  :

                  null
                }

              </Form.Field>



              <Form.Field required className="form-field">
                <label>{labels[appState.language].d2}</label>
                {errors["criminalrecord"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectfile}</Label>}
        
                <Input id="criminalrecord" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.criminalrecord?
                <Progress success={uploadProgress.criminalrecord === 100? true : false} percent={uploadProgress.criminalrecord} indicating>
                  {uploadProgress.criminalrecord === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                  </Progress>
                  :

                  null
                }

              </Form.Field>



              <Form.Field required className="form-field">
                <label>{labels[appState.language].d3}</label>
                <a href={labels[appState.language].pledgelink}><Icon name="download" /></a>
          

                {errors["pledge"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectfile}</Label>}
                <Input id="pledge" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.pledge?
                <Progress success={uploadProgress.pledge === 100? true : false} percent={uploadProgress.pledge} indicating>
                  {uploadProgress.taxreturns === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                </Progress>
                :

                null
                }
              </Form.Field>


              <Form.Field className="form-field">
                <label>{labels[appState.language].d4}</label>

                <p>{labels[appState.language].tr1}</p>
                <p>{labels[appState.language].tr2}</p>

                <Input id="taxreturns" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.taxreturns?
                <Progress success={uploadProgress.taxreturns === 100? true : false} percent={uploadProgress.taxreturns} indicating>
                  {uploadProgress.taxreturns === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                </Progress>
                :

                null
                }

              </Form.Field>


              <Form.Field className="form-field">
                <label>{labels[appState.language].d5}</label>
                  <p>{labels[appState.language].vs1}</p>

                <Input id="commercialstatement" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.commercialstatement?
                <Progress success={uploadProgress.commercialstatement === 100? true : false} percent={uploadProgress.commercialstatement} indicating>
                  {uploadProgress.commercialstatement === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                </Progress>
                :

                null
                }

              </Form.Field>


              <Form.Field className="form-field">
                <label>{labels[appState.language].d6}</label>
                  <p>{labels[appState.language].vs1}</p>
                <Input id="denialofownership" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.denialofownership?
                <Progress success={uploadProgress.denialofownership === 100? true : false} percent={uploadProgress.denialofownership} indicating>
                  {uploadProgress.denialofownership === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                </Progress>
                :

                null
                }
                

              </Form.Field>


              <Form.Field className="form-field">
                <label>{labels[appState.language].d7}</label>
                <p>{labels[appState.language].vs1}</p>
                <Input id="vehiclecertificate" type="file" onChange={handleFileChange} />
                <br/>
                <br/>
                {uploadProgress.vehiclecertificate ?
                <Progress success={uploadProgress.vehiclecertificate === 100? true : false} percent={uploadProgress.vehiclecertificate} indicating>
                  {uploadProgress.vehiclecertificate === 100?
                    <>
                       The upload was successful

                    </>

                    :
                    null

                  }
                     
                </Progress>
                :

                null
                }

              </Form.Field>

              </>

            }
              {page === 2 &&


              <>


              <h2>{labels[appState.language].questionnaire}</h2>
              <Divider />
              <p>{labels[appState.language].qintro}</p>



              <Form.Field required className="form-field">
                {errors["subjectedToDiscrimination"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q1}</label>
                <Radio
                  id="subjectedToDiscriminationYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='subjectedToDiscrimination'
                  value="yes"
                  checked={candidate['subjectedToDiscrimination'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="subjectedToDiscriminationNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='subjectedToDiscrimination'
                  value="no"
                  checked={candidate['subjectedToDiscrimination'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["subjectedToDiscriminationDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].explanation}</Label>}
                <Input
                  id="subjectedToDiscriminationDescription"
                  disabled={candidate['subjectedToDiscrimination'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['subjectedToDiscriminationDescription']}
                  onChange={handleChange} />

              </Form.Field>




              <Form.Field required className="form-field">
                {errors["subjectedAnyoneToDiscrimination"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q2}</label>
                <Radio
                  id="subjectedAnyoneToDiscriminationYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='subjectedAnyoneToDiscrimination'
                  value="yes"
                  checked={candidate['subjectedAnyoneToDiscrimination'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="subjectedAnyoneToDiscriminationNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='subjectedAnyoneToDiscrimination'
                  value="no"
                  checked={candidate['subjectedAnyoneToDiscrimination'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["subjectedAnyoneToDiscriminationDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="subjectedAnyoneToDiscriminationDescription"
                  disabled={candidate['subjectedAnyoneToDiscrimination'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['subjectedAnyoneToDiscriminationDescription']}
                  onChange={handleChange} />

              </Form.Field>




              <Form.Field required className="form-field">
                {errors["accusedOfCrime"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q3}</label>
                <Radio
                  id="accusedOfCrimeYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='accusedOfCrime'
                  value="yes"
                  checked={candidate['accusedOfCrime'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="accusedOfCrimeNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='accusedOfCrime'
                  value="no"
                  checked={candidate['accusedOfCrime'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["accusedOfCrimeDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="accusedOfCrimeDescription"
                  disabled={candidate['accusedOfCrime'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['accusedOfCrimeDescription']}
                  onChange={handleChange} />

              </Form.Field>



              <Form.Field required className="form-field">
                {errors["convictedOfCrime"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q4}</label>
                <Radio
                  id="convictedOfCrimeYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='convictedOfCrime'
                  value="yes"
                  checked={candidate['convictedOfCrime'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="convictedOfCrimeNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='convictedOfCrime'
                  value="no"
                  checked={candidate['convictedOfCrime'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["convictedOfCrimeDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="convictedOfCrimeDescription"
                  disabled={candidate['convictedOfCrime'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['convictedOfCrimeDescription']}
                  onChange={handleChange} />

              </Form.Field>


              <Form.Field required className="form-field">
                {errors["violatedAnyLaw"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q5}</label>
                <Radio
                  id="violatedAnyLawYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='violatedAnyLaw'
                  value="yes"
                  checked={candidate['violatedAnyLaw'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="violatedAnyLawNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='violatedAnyLaw'
                  value="no"
                  checked={candidate['violatedAnyLaw'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["violatedAnyLawDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="violatedAnyLawDescription"
                  disabled={candidate['violatedAnyLaw'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['violatedAnyLawDescription']}
                  onChange={handleChange} />

              </Form.Field>


              <Form.Field required className="form-field">
                {errors["subjectOfPublicScandal"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q6}</label>
                <Radio
                  id="subjectOfPublicScandalYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='subjectOfPublicScandal'
                  value="yes"
                  checked={candidate['subjectOfPublicScandal'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="subjectOfPublicScandalNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='subjectOfPublicScandal'
                  value="no"
                  checked={candidate['subjectOfPublicScandal'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["subjectOfPublicScandalDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="subjectOfPublicScandalDescription"
                  disabled={candidate['subjectOfPublicScandal'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['subjectOfPublicScandalDescription']}
                  onChange={handleChange} />

              </Form.Field>


              <Form.Field required className="form-field">
                {errors["otherCitizenship"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q7}</label>
                <Radio
                  id="otherCitizenshipYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='otherCitizenship'
                  value="yes"
                  checked={candidate['otherCitizenship'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="otherCitizenshipNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='otherCitizenship'
                  value="no"
                  checked={candidate['otherCitizenship'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["otherCitizenshipDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="otherCitizenshipDescription"
                  disabled={candidate['otherCitizenship'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['otherCitizenshipDescription']}
                  onChange={handleChange} />

              </Form.Field>


              <Form.Field required className="form-field">
                {errors["candidateForPublicPosition"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q8}</label>
                <Radio
                  id="candidateForPublicPositionYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='candidateForPublicPosition'
                  value="yes"
                  checked={candidate['candidateForPublicPosition'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="candidateForPublicPositionNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='candidateForPublicPosition'
                  value="no"
                  checked={candidate['candidateForPublicPosition'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["candidateForPublicPositionDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="candidateForPublicPositionDescription"
                  disabled={candidate['candidateForPublicPosition'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['candidateForPublicPositionDescription']}
                  onChange={handleChange} />

              </Form.Field>


              <Form.Field required className="form-field">
                {errors["activeMemberInPoliticalParty"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q9}</label>
                <Radio
                  id="activeMemberInPoliticalPartyYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='activeMemberInPoliticalParty'
                  value="yes"
                  checked={candidate['activeMemberInPoliticalParty'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="activeMemberInPoliticalPartyNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='activeMemberInPoliticalParty'
                  value="no"
                  checked={candidate['activeMemberInPoliticalParty'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["activeMemberInPoliticalPartyDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="activeMemberInPoliticalPartyDescription"
                  disabled={candidate['activeMemberInPoliticalParty'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['activeMemberInPoliticalPartyDescription']}
                  onChange={handleChange} />

              </Form.Field>


              <Form.Field required className="form-field">
                {errors["affiliationWithPoliticalParty"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q10}</label>
                <Radio
                  id="affiliationWithPoliticalPartyYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='affiliationWithPoliticalParty'
                  value="yes"
                  checked={candidate['affiliationWithPoliticalParty'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="affiliationWithPoliticalPartyNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='affiliationWithPoliticalParty'
                  value="no"
                  checked={candidate['affiliationWithPoliticalParty'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["affiliationWithPoliticalPartyDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="affiliationWithPoliticalPartyDescription"
                  disabled={candidate['affiliationWithPoliticalParty'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['affiliationWithPoliticalPartyDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* donatedToCampaigns */}
              <Form.Field required className="form-field">
                {errors["donatedToCampaigns"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q11}</label>
                <Radio
                  id="donatedToCampaignsYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='donatedToCampaigns'
                  value="yes"
                  checked={candidate['donatedToCampaigns'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="donatedToCampaignsNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='donatedToCampaigns'
                  value="no"
                  checked={candidate['donatedToCampaigns'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["donatedToCampaignsDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="donatedToCampaignsDescription"
                  disabled={candidate['donatedToCampaigns'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['donatedToCampaignsDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* involvedInBankruptcy */}
              <Form.Field required className="form-field">
                {errors["involvedInBankruptcy"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q12}</label>
                <Radio
                  id="involvedInBankruptcyYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='involvedInBankruptcy'
                  value="yes"
                  checked={candidate['involvedInBankruptcy'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="involvedInBankruptcyNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='involvedInBankruptcy'
                  value="no"
                  checked={candidate['involvedInBankruptcy'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["involvedInBankruptcyDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="involvedInBankruptcyDescription"
                  disabled={candidate['involvedInBankruptcy'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['involvedInBankruptcyDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* accussedOfTaxEvasion */}
              <Form.Field required className="form-field">
                {errors["accussedOfTaxEvasion"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q13}</label>
                <Radio
                  id="accussedOfTaxEvasionYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='accussedOfTaxEvasion'
                  value="yes"
                  checked={candidate['accussedOfTaxEvasion'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="accussedOfTaxEvasionNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='accussedOfTaxEvasion'
                  value="no"
                  checked={candidate['accussedOfTaxEvasion'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["accussedOfTaxEvasionDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="accussedOfTaxEvasionDescription"
                  disabled={candidate['accussedOfTaxEvasion'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['accussedOfTaxEvasionDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* audited */}
              <Form.Field required className="form-field">
                {errors["audited"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q14}</label>
                <Radio
                  id="auditedYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='audited'
                  value="yes"
                  checked={candidate['audited'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="auditedNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='audited'
                  value="no"
                  checked={candidate['audited'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["auditedDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="auditedDescription"
                  disabled={candidate['audited'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['auditedDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* involvedInGovernmentProject */}
              <Form.Field required className="form-field">
                {errors["involvedInGovernmentProject"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q15}</label>
                <Radio
                  id="involvedInGovernmentProjectYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='involvedInGovernmentProject'
                  value="yes"
                  checked={candidate['involvedInGovernmentProject'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="involvedInGovernmentProjectNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='involvedInGovernmentProject'
                  value="no"
                  checked={candidate['involvedInGovernmentProject'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["involvedInGovernmentProjectDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="involvedInGovernmentProjectDescription"
                  disabled={candidate['involvedInGovernmentProject'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['involvedInGovernmentProjectDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* involvedInNonGovernmentProject */}
              <Form.Field required className="form-field">
                {errors["involvedInNonGovernmentProject"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q16}</label>
                <Radio
                  id="involvedInNonGovernmentProjectYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='involvedInNonGovernmentProject'
                  value="yes"
                  checked={candidate['involvedInNonGovernmentProject'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="involvedInNonGovernmentProjectNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='involvedInNonGovernmentProject'
                  value="no"
                  checked={candidate['involvedInNonGovernmentProject'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["involvedInNonGovernmentProjectDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="involvedInNonGovernmentProjectDescription"
                  disabled={candidate['involvedInNonGovernmentProject'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['involvedInNonGovernmentProjectDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* appointedAsConsultantByGovernment */}
              <Form.Field required className="form-field">
                {errors["appointedAsConsultantByGovernment"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q17}</label>
                <Radio
                  id="appointedAsConsultantByGovernmentYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='appointedAsConsultantByGovernment'
                  value="yes"
                  checked={candidate['appointedAsConsultantByGovernment'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="appointedAsConsultantByGovernmentNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='appointedAsConsultantByGovernment'
                  value="no"
                  checked={candidate['appointedAsConsultantByGovernment'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["appointedAsConsultantByGovernmentDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="appointedAsConsultantByGovernmentDescription"
                  disabled={candidate['appointedAsConsultantByGovernment'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['appointedAsConsultantByGovernmentDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* fundedByNonGovernment */}
              <Form.Field required className="form-field">
                {errors["fundedByNonGovernment"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q18}</label>
                <Radio
                  id="fundedByNonGovernmentYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='fundedByNonGovernment'
                  value="yes"
                  checked={candidate['fundedByNonGovernment'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="fundedByNonGovernmentNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='fundedByNonGovernment'
                  value="no"
                  checked={candidate['fundedByNonGovernment'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["fundedByNonGovernmentDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="fundedByNonGovernmentDescription"
                  disabled={candidate['fundedByNonGovernment'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['fundedByNonGovernmentDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* fundedByGovernment */}
              <Form.Field required className="form-field">
                {errors["fundedByGovernment"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q19}</label>
                <Radio
                  id="fundedByGovernmentYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='fundedByGovernment'
                  value="yes"
                  checked={candidate['fundedByGovernment'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="fundedByGovernmentNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='fundedByGovernment'
                  value="no"
                  checked={candidate['fundedByGovernment'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["fundedByGovernmentDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="fundedByGovernmentDescription"
                  disabled={candidate['fundedByGovernment'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['fundedByGovernmentDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* bankAccountOutsideLebanon */}
              <Form.Field required className="form-field">
                {errors["bankAccountOutsideLebanon"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q20}</label>
                <Radio
                  id="bankAccountOutsideLebanonYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='bankAccountOutsideLebanon'
                  value="yes"
                  checked={candidate['bankAccountOutsideLebanon'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="bankAccountOutsideLebanonNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='bankAccountOutsideLebanon'
                  value="no"
                  checked={candidate['bankAccountOutsideLebanon'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["bankAccountOutsideLebanonDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="bankAccountOutsideLebanonDescription"
                  disabled={candidate['bankAccountOutsideLebanon'] !== 'yes'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['bankAccountOutsideLebanonDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* stripYourselfFromPoliticalImmunity */}
              <Form.Field required className="form-field">
                {errors["stripYourselfFromPoliticalImmunity"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q21}</label>
                <Radio
                  id="stripYourselfFromPoliticalImmunityYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='stripYourselfFromPoliticalImmunity'
                  value="yes"
                  checked={candidate['stripYourselfFromPoliticalImmunity'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="stripYourselfFromPoliticalImmunityNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='stripYourselfFromPoliticalImmunity'
                  value="no"
                  checked={candidate['stripYourselfFromPoliticalImmunity'] === 'no'}
                  onChange={handleRadioChange}
                />
                {errors["stripYourselfFromPoliticalImmunityDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="stripYourselfFromPoliticalImmunityDescription"
                  disabled={candidate['stripYourselfFromPoliticalImmunity'] !== 'no'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['stripYourselfFromPoliticalImmunityDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* removeYourselfFromConflictOfInterest */}
              <Form.Field required className="form-field">
                {errors["removeYourselfFromConflictOfInterest"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <label>{labels[appState.language].q22}</label>
                <Radio
                  id="removeYourselfFromConflictOfInterestYes"
                  className="radio"
                  label={labels[appState.language].yes}
                  name='removeYourselfFromConflictOfInterest'
                  value="yes"
                  checked={candidate['removeYourselfFromConflictOfInterest'] === 'yes'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="removeYourselfFromConflictOfInterestNo"
                  className="radio"
                  label={labels[appState.language].no}
                  name='removeYourselfFromConflictOfInterest'
                  value="no"
                  checked={candidate['removeYourselfFromConflictOfInterest'] === 'no'}
                  onChange={handleRadioChange}
                />

                {errors["removeYourselfFromConflictOfInterestDescription"] && <Label basic color='red' pointing='below'>{labels[appState.language].selectanswer}</Label>}
                <Input
                  id="removeYourselfFromConflictOfInterestDescription"
                  disabled={candidate['removeYourselfFromConflictOfInterest'] !== 'no'}
                  placeholder={labels[appState.language].elaborate}
                  value={candidate['removeYourselfFromConflictOfInterestDescription']}
                  onChange={handleChange} />

              </Form.Field>





                </>


              }



              {page === 3 &&

              <>


              <h2>{labels[appState.language].whereyoustand}</h2>
              <Divider />
              <p>{labels[appState.language].wintro}</p>

              {/* onSeparationOfReligionAndState */}
              <Form.Field required className="form-field">
                {errors["onSeparationOfReligionAndState"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w1}</label>
                <Radio
                  id="onSeparationOfReligionAndStateWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onSeparationOfReligionAndState'
                  value="with"
                  checked={candidate['onSeparationOfReligionAndState'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onSeparationOfReligionAndStateAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onSeparationOfReligionAndState'
                  value="against"
                  checked={candidate['onSeparationOfReligionAndState'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onSeparationOfReligionAndStateDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onSeparationOfReligionAndStateDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onLegalizingCivilMarriage */}
              <Form.Field required className="form-field">
                {errors["onLegalizingCivilMarriage"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w2}</label>
                <Radio
                  id="onLegalizingCivilMarriageWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLegalizingCivilMarriage'
                  value="with"
                  checked={candidate['onLegalizingCivilMarriage'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLegalizingCivilMarriageAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLegalizingCivilMarriage'
                  value="against"
                  checked={candidate['onLegalizingCivilMarriage'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLegalizingCivilMarriageDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLegalizingCivilMarriageDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onLegalizingMarijuana */}
              <Form.Field required className="form-field">
                {errors["onLegalizingMarijuana"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w3}</label>
                <Radio
                  id="onLegalizingMarijuanaWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLegalizingMarijuana'
                  value="with"
                  checked={candidate['onLegalizingMarijuana'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLegalizingMarijuanaAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLegalizingMarijuana'
                  value="against"
                  checked={candidate['onLegalizingMarijuana'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLegalizingMarijuanaDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLegalizingMarijuanaDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onLGBTQ */}
              <Form.Field required className="form-field">
                {errors["onLGBTQ"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w4}</label>
                <Radio
                  id="onLGBTQWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLGBTQ'
                  value="with"
                  checked={candidate['onLGBTQ'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLGBTQAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLGBTQ'
                  value="against"
                  checked={candidate['onLGBTQ'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLGBTQDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLGBTQDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onLegalizingAbortion */}
              <Form.Field required className="form-field">
                {errors["onLegalizingAbortion"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w5}</label>
                <Radio
                  id="onLegalizingAbortionWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLegalizingAbortion'
                  value="with"
                  checked={candidate['onLegalizingAbortion'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLegalizingAbortionAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLegalizingAbortion'
                  value="against"
                  checked={candidate['onLegalizingAbortion'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLegalizingAbortionDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLegalizingAbortionDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onUnifyingPersonalStatusLaws */}
              <Form.Field required className="form-field">
                {errors["onUnifyingPersonalStatusLaws"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w6}</label>
                <Radio
                  id="onUnifyingPersonalStatusLawsWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onUnifyingPersonalStatusLaws'
                  value="with"
                  checked={candidate['onUnifyingPersonalStatusLaws'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onUnifyingPersonalStatusLawsAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onUnifyingPersonalStatusLaws'
                  value="against"
                  checked={candidate['onUnifyingPersonalStatusLaws'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onUnifyingPersonalStatusLawsDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onUnifyingPersonalStatusLawsDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onUnifyingInheritanceLaws */}
              <Form.Field required className="form-field">
                {errors["onUnifyingInheritanceLaws"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w7}</label>
                <Radio
                  id="onUnifyingInheritanceLawsWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onUnifyingInheritanceLaws'
                  value="with"
                  checked={candidate['onUnifyingInheritanceLaws'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onUnifyingInheritanceLawsAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onUnifyingInheritanceLaws'
                  value="against"
                  checked={candidate['onUnifyingInheritanceLaws'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onUnifyingInheritanceLawsDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onUnifyingInheritanceLawsDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onProstitution */}
              <Form.Field required className="form-field">
                {errors["onProstitution"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w8}</label>
                <Radio
                  id="onProstitutionWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onProstitution'
                  value="with"
                  checked={candidate['onProstitution'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onProstitutionAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onProstitution'
                  value="against"
                  checked={candidate['onProstitution'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onProstitutionDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onProstitutionDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onFreedomOfPress */}
              <Form.Field required className="form-field">
                {errors["onFreedomOfPress"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w9}</label>
                <Radio
                  id="onFreedomOfPressWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onFreedomOfPress'
                  value="with"
                  checked={candidate['onFreedomOfPress'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onFreedomOfPressAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onFreedomOfPress'
                  value="against"
                  checked={candidate['onFreedomOfPress'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onFreedomOfPressDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onFreedomOfPressDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onFreedomOfSpeech */}
              <Form.Field required className="form-field">
                {errors["onFreedomOfSpeech"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w10}</label>
                <Radio
                  id="onFreedomOfSpeechWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onFreedomOfSpeech'
                  value="with"
                  checked={candidate['onFreedomOfSpeech'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onFreedomOfSpeechAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onFreedomOfSpeech'
                  value="against"
                  checked={candidate['onFreedomOfSpeech'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onFreedomOfSpeechDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onFreedomOfSpeechDescription']}
                  onChange={handleChange} />

              </Form.Field>



              {/* onIncorporationOfTechnology */}
              <Form.Field required className="form-field">
                {errors["onIncorporationOfTechnology"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w11}</label>
                <Radio
                  id="onIncorporationOfTechnologyWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onIncorporationOfTechnology'
                  value="with"
                  checked={candidate['onIncorporationOfTechnology'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onIncorporationOfTechnologyAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onIncorporationOfTechnology'
                  value="against"
                  checked={candidate['onIncorporationOfTechnology'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onIncorporationOfTechnologyDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onIncorporationOfTechnologyDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onWomenRights */}
              <Form.Field required className="form-field">
                {errors["onWomenRights"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w12}</label>
                <Radio
                  id="onWomenRightsWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onWomenRights'
                  value="with"
                  checked={candidate['onWomenRights'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onWomenRightsAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onWomenRights'
                  value="against"
                  checked={candidate['onWomenRights'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onWomenRightsDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onWomenRightsDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onChildrenRights */}
              <Form.Field required className="form-field">
                {errors["onChildrenRights"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w13}</label>
                <Radio
                  id="onChildrenRightsWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onChildrenRights'
                  value="with"
                  checked={candidate['onChildrenRights'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onChildrenRightsAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onChildrenRights'
                  value="against"
                  checked={candidate['onChildrenRights'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onChildrenRightsDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onChildrenRightsDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onLebaneseMothers */}
              <Form.Field required className="form-field">
                {errors["onLebaneseMothers"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w14}</label>
                <Radio
                  id="onLebaneseMothersWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLebaneseMothers'
                  value="with"
                  checked={candidate['onLebaneseMothers'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLebaneseMothersAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLebaneseMothers'
                  value="against"
                  checked={candidate['onLebaneseMothers'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLebaneseMothersDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLebaneseMothersDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onStatelessPeople */}
              <Form.Field required className="form-field">
                {errors["onStatelessPeople"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w15}</label>
                <Radio
                  id="onStatelessPeopleWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onStatelessPeople'
                  value="with"
                  checked={candidate['onStatelessPeople'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onStatelessPeopleAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onStatelessPeople'
                  value="against"
                  checked={candidate['onStatelessPeople'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onStatelessPeopleDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onStatelessPeopleDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onPalestinianRefugees */}
              <Form.Field required className="form-field">
                {errors["onPalestinianRefugees"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w16}</label>
                <Radio
                  id="onPalestinianRefugeesWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onPalestinianRefugees'
                  value="with"
                  checked={candidate['onPalestinianRefugees'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onPalestinianRefugeesAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onPalestinianRefugees'
                  value="against"
                  checked={candidate['onPalestinianRefugees'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onPalestinianRefugeesDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onPalestinianRefugeesDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onGrantingSyriansRefugeeStatus */}
              <Form.Field required className="form-field">
                {errors["onGrantingSyriansRefugeeStatus"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w17}</label>
                <Radio
                  id="onGrantingSyriansRefugeeStatusWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onGrantingSyriansRefugeeStatus'
                  value="with"
                  checked={candidate['onGrantingSyriansRefugeeStatus'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onGrantingSyriansRefugeeStatusAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onGrantingSyriansRefugeeStatus'
                  value="against"
                  checked={candidate['onGrantingSyriansRefugeeStatus'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onGrantingSyriansRefugeeStatusDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onGrantingSyriansRefugeeStatusDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onGovernmentLootedFunds */}
              <Form.Field required className="form-field">
                {errors["onGovernmentLootedFunds"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w18}</label>
                <Radio
                  id="onGovernmentLootedFundsWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onGovernmentLootedFunds'
                  value="with"
                  checked={candidate['onGovernmentLootedFunds'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onGovernmentLootedFundsAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onGovernmentLootedFunds'
                  value="against"
                  checked={candidate['onGovernmentLootedFunds'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onGovernmentLootedFundsDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onGovernmentLootedFundsDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onEnvironment */}
              <Form.Field required className="form-field">
                {errors["onEnvironment"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w19}</label>
                <Radio
                  id="onEnvironmentWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onEnvironment'
                  value="with"
                  checked={candidate['onEnvironment'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onEnvironmentAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onEnvironment'
                  value="against"
                  checked={candidate['onEnvironment'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onEnvironmentDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onEnvironmentDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onMeritocracy */}
              <Form.Field required className="form-field">
                {errors["onMeritocracy"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w20}</label>
                <Radio
                  id="onMeritocracyWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onMeritocracy'
                  value="with"
                  checked={candidate['onMeritocracy'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onMeritocracyAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onMeritocracy'
                  value="against"
                  checked={candidate['onMeritocracy'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onMeritocracyDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onMeritocracyDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onTheConstitution */}
              <Form.Field required className="form-field">
                {errors["onTheConstitution"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w21}</label>
                <Radio
                  id="onTheConstitutionWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onTheConstitution'
                  value="with"
                  checked={candidate['onTheConstitution'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onTheConstitutionAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onTheConstitution'
                  value="against"
                  checked={candidate['onTheConstitution'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onTheConstitutionDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onTheConstitutionDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* onElectingPresidentByThePeople */}
              <Form.Field required className="form-field">
                {errors["onElectingPresidentByThePeople"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w22}</label>
                <Radio
                  id="onElectingPresidentByThePeopleWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onElectingPresidentByThePeople'
                  value="with"
                  checked={candidate['onElectingPresidentByThePeople'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onElectingPresidentByThePeopleAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onElectingPresidentByThePeople'
                  value="against"
                  checked={candidate['onElectingPresidentByThePeople'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onElectingPresidentByThePeopleDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onElectingPresidentByThePeopleDescription']}
                  onChange={handleChange} />

              </Form.Field>



              {/* onPeggingLiraToUsd */}
              <Form.Field required className="form-field">
                {errors["onPeggingLiraToUsd"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w23}</label>
                <Radio
                  id="onPeggingLiraToUsdWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onPeggingLiraToUsd'
                  value="with"
                  checked={candidate['onPeggingLiraToUsd'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onPeggingLiraToUsdAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onPeggingLiraToUsd'
                  value="against"
                  checked={candidate['onPeggingLiraToUsd'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onPeggingLiraToUsdDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onPeggingLiraToUsdDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onLebaneseDiaspora */}
              <Form.Field required className="form-field">
                {errors["onLebaneseDiaspora"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w24}</label>
                <Radio
                  id="onLebaneseDiasporaWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLebaneseDiaspora'
                  value="with"
                  checked={candidate['onLebaneseDiaspora'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLebaneseDiasporaAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLebaneseDiaspora'
                  value="against"
                  checked={candidate['onLebaneseDiaspora'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLebaneseDiasporaDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLebaneseDiasporaDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onFemaleQuota */}
              <Form.Field required className="form-field">
                {errors["onFemaleQuota"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w25}</label>
                <Radio
                  id="onFemaleQuotaWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onFemaleQuota'
                  value="with"
                  checked={candidate['onFemaleQuota'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onFemaleQuotaAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onFemaleQuota'
                  value="against"
                  checked={candidate['onFemaleQuota'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onFemaleQuotaDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onFemaleQuotaDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onNewElectoralLaw */}
              <Form.Field required className="form-field">
                {errors["onNewElectoralLaw"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w26}</label>
                <Radio
                  id="onNewElectoralLawWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onNewElectoralLaw'
                  value="with"
                  checked={candidate['onNewElectoralLaw'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onNewElectoralLawAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onNewElectoralLaw'
                  value="against"
                  checked={candidate['onNewElectoralLaw'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onNewElectoralLawDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onNewElectoralLawDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onEarlyParliamentaryElections */}
              <Form.Field required className="form-field">
                {errors["onEarlyParliamentaryElections"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w27}</label>
                <Radio
                  id="onEarlyParliamentaryElectionsWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onEarlyParliamentaryElections'
                  value="with"
                  checked={candidate['onEarlyParliamentaryElections'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onEarlyParliamentaryElectionsAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onEarlyParliamentaryElections'
                  value="against"
                  checked={candidate['onEarlyParliamentaryElections'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onEarlyParliamentaryElectionsDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onEarlyParliamentaryElectionsDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onTheLebaneseRevolution */}
              <Form.Field required className="form-field">
                {errors["onTheLebaneseRevolution"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w28}</label>
                <Radio
                  id="onTheLebaneseRevolutionWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onTheLebaneseRevolution'
                  value="with"
                  checked={candidate['onTheLebaneseRevolution'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onTheLebaneseRevolutionAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onTheLebaneseRevolution'
                  value="against"
                  checked={candidate['onTheLebaneseRevolution'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onTheLebaneseRevolutionDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onTheLebaneseRevolutionDescription']}
                  onChange={handleChange} />

              </Form.Field>

              {/* onLebnonAndSyria */}
              <Form.Field required className="form-field">
                {errors["onLebnonAndSyria"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w29}</label>
                <Radio
                  id="onLebnonAndSyriaWith"
                  className="radio"
                  label={labels[appState.language].with}
                  name='onLebnonAndSyria'
                  value="with"
                  checked={candidate['onLebnonAndSyria'] === 'with'}
                  onChange={handleRadioChange}
                />
                <Radio
                  id="onLebnonAndSyriaAgainst"
                  className="radio"
                  label={labels[appState.language].against}
                  name='onLebnonAndSyria'
                  value="against"
                  checked={candidate['onLebnonAndSyria'] === 'against'}
                  onChange={handleRadioChange}
                />
                <Input
                  id="onLebnonAndSyriaDescription"
                  placeholder={labels[appState.language].details}
                  value={candidate['onLebnonAndSyriaDescription']}
                  onChange={handleChange} />

              </Form.Field>


              {/* roleModel */}
              <Form.Field required className="form-field">
                {errors["roleModel"] && <Label basic color='red' pointing='below'>{labels[appState.language].entervalue}</Label>}
                <label>{labels[appState.language].w30}</label>
                <Input
                  id="roleModel"
                  placeholder=''
                  value={candidate['roleModel']}
                  onChange={handleChange} />

              </Form.Field>

              </>

            }

              {Object.keys(errors).length > 0 && Object.values(errors).includes(true) &&
                <Message negative>
                  <Message.Header> {labels[appState.language].errorheader}</Message.Header>
                  <p>{labels[appState.language].error}</p>
                  <p> {labels[appState.language].missingfields(Object.keys(errors).length)} </p>
                  <p> {labels[appState.language].missingOnOtherPages}</p>
                </Message>
              }


             


                <div id="control-buttons">
                  <div>
             
                  <Button loading={isLoading} disabled={isLoading} color="teal" type='submit' onClick={submit}>{labels[appState.language].submit}</Button>


                  <Button secondary type='cancel' onClick={props.onCancel}>{labels[appState.language].cancel}</Button>

                  </div>

                  <div id="page-buttons">
                    <Button.Group>
                    <Button disabled={page=== 1} onClick={() => changePage(1)}>Page 1</Button>
                    <Button disabled={page=== 2} onClick={() => changePage(2)}>Page 2</Button>
                    <Button disabled={page=== 3} onClick={() => changePage(3)}>Page 3</Button>
                  </Button.Group>

                  </div>
                </div>
           

             

            </Form>



          </div>


          </div>
  
      }

    </Container>
  )
}

export default (props) => {

  const [, doAction] = useContext(CTX);

  const updateUser = (user) => {
    doAction({ type: message_types.SET_USER, payload: user })
  }

  return (


      
  <Authenticator hide={[SignUp, SignIn, Greetings]}>
    <CustomSignIn updateUser={updateUser} override={'SignIn'} />
    <CustomSignUp override={'SignUp'} />
    <Application {...props} />
  </Authenticator> 


  )
}

