import React from 'react'
import { Form, Modal, Header } from 'semantic-ui-react';
import { SignIn } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';


import './index.css'


class CustomSignIn extends SignIn {


  initialState = {
    username: '',
    password: '',
    isModalOpen: false,
    error: ''
  }

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }


  signIn() {

    Auth.signIn({
      username: this.state.username,
      password: this.state.password
    })
      .then((res) => {
        this.setState({ ...this.state, password: '' })
        this.props.updateUser(res)
        super.changeState('signedIn', { username: this.state.username })
      })
      .catch(e => {
        this.setState({ ...this.state, password: '' })

        this.setState({ ...this.state, error: "Sorry, we could not sign you in." })
        this.setState({ ...this.state, isModalOpen: true })
      });

  }

  handleChange(e) {
    if (e.target.id === "username") {

      this.setState({ ...this.state, [e.target.id]: e.target.value.toLowerCase() });

    } else {
      this.setState({ ...this.state, [e.target.id]: e.target.value });
    }
  }



  showComponent(theme) {

    return (
      <div className="signup-container">
        <span className="signup-title">Sign in to your account</span>

        <Form theme={theme}>

          <Form.Field required>
            <label className="signup-label">Username (lowercase only)</label>
            <input id="username"
              placeholder="Username"
              className="signup-input-field"
              value={this.state.username}
              onChange={this.handleChange.bind(this)} />
          </Form.Field>

          <Form.Field required>
            <label className="signup-label">Password</label>
            <input value={this.state.password}
              type="password" id="password" placeholder="Password" className="signup-input-field" onChange={this.handleChange.bind(this)} />
          </Form.Field>


          <Form.Field className="signup-footer">
            <span className="signin-forgot-password">Forgot your password? <span className="fake-link" onClick={() => this.props.onStateChange('forgotPassword', {})}>Reset password</span></span>
          </Form.Field>

          {/* Rawad come back here for href */}
          <Form.Field className="signup-footer">
            <span className="signup-footer-note">No account? <span className="fake-link" onClick={() => this.props.onStateChange('signUp', {})}>Create account</span></span>
            <button onClick={this.signIn.bind()}>SIGN IN</button>
          </Form.Field>
        </Form>


        <Modal open={this.state.isModalOpen} closeIcon onClose={() => this.setState({ ...this.state, isModalOpen: false })}>
          <Header content='Error signing in' color="red" inverted />
          <Modal.Content>
            <p>
              {this.state.error}
            </p>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default CustomSignIn;
