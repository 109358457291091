import React from 'react'
import { Form, Modal, Header } from 'semantic-ui-react';
import { SignUp } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import './index.css'


class CustomSignUp extends SignUp {

  initialState = {
    username: '',
    password: '',
    confirm_password: '',
    email: '',
    phone_number: '+961',
    isModalOpen: false,
    error: '',
    field_errors: {
      username: false,
      email: false,
      password: false,
      confirm_password: false
    },
    submitting: false
  }

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this._validAuthStates = ["signUp"];
  }

  


  signUp() {
    
    this.setState((prevState)=> 
        { return {
            ...prevState,
            submitting: true,
            field_errors: {
              ...this.initialState.field_errors
            }
          }
        }
    );

    if(!this.state.username ) {
      this.setState((prevState)=> 
        { return {
            ...prevState,
            error: "Please enter a username.",
            isModalOpen: true,
            submitting: false,
            field_errors: {
              ...prevState.field_errors,
              username: true
            }
          }
        }
      );
      return;
    }

    if(this.state.username.includes(' ') ) {
      this.setState((prevState)=> 
        { return {
            ...prevState,
            error: "Username should not contain spaces",
            isModalOpen: true,
            submitting: false,
            field_errors: {
              ...prevState.field_errors,
              username: true
            }
          }
        }
      );
      return;
    }

    if( this.state.password.length < 8) {
      this.setState((prevState)=> 
        { return {
        
            ...prevState, 
            error: "Password should be a minimum of 8 characters",
            submitting: false,
            isModalOpen: true,
            field_errors: {
              ...prevState.field_errors,
              password: true
            }
          }
        }
      )
      return;
    }

    if (this.state.password !== this.state.confirm_password) {

      this.setState((prevState)=> 
        { return {
        
            ...prevState, 
            error: "Passwords do not match!",
            submitting:false,
            isModalOpen: true,
            field_errors: {
              ...prevState.field_errors,
              confirm_password: true
            }
          }
        }
      )
      return;
    }


    

    if (!this.state.email) {
      this.setState((prevState)=> 
        { return {
        
            ...prevState, 
            error: "Please enter an email address.",
            submitting:false,
            isModalOpen: true,
            field_errors: {
              ...prevState.field_errors,
              email: true
            }
          }
        }
      )

      return
    }



    var attributes = {};
    attributes.email = this.state.email;
    if (this.state.phone_number && this.state.phone_number !== '+961') {
      attributes.phone_number = this.state.phone_number
    }

    Auth.signUp({
      username: this.state.username,
      password: this.state.password,
      attributes
    })
      .then(() => {
        this.setState({ ...this.state, submitting: false, password: '', confirm_password: '' })
        super.changeState('confirmSignUp', { username: this.state.username })
      })
      .catch(e => {

        console.log("An exception happened during sign up:", e)

        let err = '';

        if (e.code === 'UsernameExistsException') {
          err = 'Username is already being used. Please choose another Username'
        } else if (e.code ==="InvalidPasswordException") {
          err = e.message + ". Password should be at least 8 characters long"
        } else if (e.code === "InvalidParameterException") {
          err = e.message
        } else if (e.code ==="NetworkError") {
          err = "There was a problem signing you up. Please verify you are connected to the internet."
        } else {
          err = 'There was a problem signing you up. Please contact us if the problem persists.'
        }  

        this.setState((prevState)=> 
          { return {
          
              ...prevState, 
              password: '',
              confirm_password: '',
              error: err,
              isModalOpen: true,
              submitting: false
            }
          }
        )

      });
 

  }

  handleChange(e) {
    if (e.target.id === "username") {

      this.setState({ ...this.state, [e.target.id]: e.target.value.toLowerCase() });

    } else {
      this.setState({ ...this.state, [e.target.id]: e.target.value });
    }
  }


  handlePhoneChange(e) {
    console.log(e)
    this.setState({ ...this.state, "phone_number": e })
  }

  showComponent(theme) {

    return (
      <div className="signup-container">
        <span className="signup-title">Create an account</span>

        <Form theme={theme}>

          <Form.Field  error={this.state.field_errors.username} required>
            <label className="signup-label">Username (lowercase only)</label>
            <input id="username"
              placeholder="Username"
              className="signup-input-field"
              value={this.state.username}
              onChange={this.handleChange.bind(this)} />
          </Form.Field>

          <Form.Field error={this.state.field_errors.password} required>
            <label className="signup-label">Password</label>
            <input value={this.state.password}
              type="password" id="password" placeholder="Password" className="signup-input-field" onChange={this.handleChange.bind(this)} />
          </Form.Field>

          <Form.Field error={this.state.field_errors.confirm_password} required>
            <label className="signup-label">Confirm Password</label>
            <input value={this.state.confirm_password}
              type="password" id="confirm_password" placeholder="Confirm Password" className="signup-input-field" onChange={this.handleChange.bind(this)} />
          </Form.Field>

          <Form.Field error={this.state.field_errors.email} required>
            <label className="signup-label">Email</label>
            <input value={this.state.email}
              id="email" placeholder="A confirmation code will be sent" className="signup-input-field" onChange={this.handleChange.bind(this)} />
          </Form.Field>

          <Form.Field>
            <label className="signup-label">Mobile Phone Number (Optional)</label>
            <PhoneInput
              id="phone_number"
              className="signup-phone-field"
              placeholder="Phone Number"
              value={this.state.phone_number}
              onChange={this.handlePhoneChange.bind(this)} />
            {/* </Input> */}
          </Form.Field>


          {/* Rawad come back here for href */}
          <Form.Field className="signup-footer">
            <span className="signup-footer-note">Have an account? <span className="fake-link" onClick={() => this.props.onStateChange('signIn', {})}>Sign in</span></span>
            <button disabled={this.state.submitting} onClick={this.signUp.bind()}>CREATE ACCOUNT</button>
          </Form.Field>
        </Form>


        <Modal open={this.state.isModalOpen} closeIcon onClose={() => this.setState({ ...this.state, isModalOpen: false })}>
          <Header content='Error signing up' color="red" inverted />
          <Modal.Content>
            <p>
              {this.state.error}
            </p>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default CustomSignUp;
