import React, { useContext, useEffect, useState } from 'react'
import { Container, Table, Button } from 'semantic-ui-react'
import { CTX } from '../state/store';

import './index.css'

import { useLocation, useHistory } from "react-router-dom";
import { message_types, pages } from '../state/constants';

import * as api from '../api';


export default () => {

  const [candidates, setCandidates] = useState({ items: [] });
  const [, doAction] = useContext(CTX);
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const page = location.pathname.substr(1);
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })

    api.listCandidates().then(res => {
      setCandidates(res)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Container className="admin-container">



      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
             <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>

          {candidates.items.map(item => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.preferredEmail || 'N/A'}</Table.Cell>
                <Table.Cell>{item.preferredPhoneNumber || 'N/A'}</Table.Cell>
                <Table.Cell>{item.status || 'N/A'}</Table.Cell>
                <Table.Cell><Button onClick={() => history.push("/" + pages.CANDIDATE + "/" + item.id)} basic color="teal">View</Button></Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Container>
  )
}
