import React from 'react'
import './index.css'

export default () => {

  return (
    <div className="application-textblock-en-container">
      <p>If any of the requested documents will not be supplied with your application,
  a full written reasoning must be given at the time of application as to why
  not.</p>

      <p>If in the case of tax returns the reason is you need time to have the
  documents prepared you must submit a firm deadline for when they will be
  submitted. This deadline must be communicated in a document
  uploaded in the tax returns section.</p>

      <p>Remember, you are not required to submit anything, doing so is of your own
  volition, however your choice is a part of your presentation to the people
  and we leave it to you to decide how you wish to present yourself</p>

    </div>
  )
}
