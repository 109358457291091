import React from 'react'
import './index.css'
import { Divider } from 'semantic-ui-react'

export default () => {


  const p2 = "is setup to bring to the Lebanese people a robust \n" +
    "and neutral evaluation method of all candidates who choose to \n" +
    "participate in the platform, abide by its rules of eligibility and are \n" +
    "chosen to be featured on the platform by vote on the platform."

  const p3 = "subsequently has the potential to empower any \n" +
    "candidate seeking the privilege of serving the Lebanese people in \n" +
    "government, should they earn the favor of the Lebanese people after having participated in the platform."

  const p4 = "will never be partisan, will never introduce bias, \n" +
    "and will never endorse, recommend, or advise any Lebanese \n" +
    "citizen, or group, of a candidate to support."

  const p5 = "operates on the tenet of having faith in the \n" +
    "Lebanese people and their ability to decide, by the power of their \n" +
    "vote, who best represents their interests. To have faith in the \n" +
    "people of Lebanon is to have faith in their judgement, and the \n" +
    "purpose of The Initiative is to help provide the understanding with \n" +
    "which the Lebanese people can make informed decisions for new \n" +
    "representation."

  const p6 = "will have one speaker on its behalf, it is to be the \n" +
    "same person who conducts all interviews. In its debut The \n" +
    "Initiative is led by its founder Nemr Abou Nassar, however The \n" +
    "Initiative has been set up in a way to allow for its full functionality \n" +
    "in service of the Lebanese people with or without its founders' involvement."

  const p7 = "is made up of many Lebanese volunteers from all \n" +
    "backgrounds that have been included based solely on their \n" +
    "qualifications to ensure a diverse representation of the Lebanese \n" +
    "people, guarantee no bias, and ensure the Neutrality of the \n" +
    "platform."

  const p8 = "shall always be in service of the Lebanese people."

  return (
    <div className="home-textblock-en-container">
      <p><b>The Initiative</b> {p2}</p>
      <Divider />
      <p><b>The Initiative</b> {p3}</p>
      <Divider />
      <p><b>The Initiative</b> {p4}</p>
      <Divider />
      <p><b>The Initiative</b> {p5}</p>
      <Divider />
      <p><b>The Initiative</b> {p6}</p>
      <Divider />
      <p><b>The Initiative</b> {p7}</p>
      <Divider />
      <p><b>The Initiative</b> {p8}</p>
    </div>
  )
}
