import React, { useContext, useEffect } from 'react'
import { Container } from 'semantic-ui-react'
import { CTX } from '../state/store';

import './index.css'

import { useLocation } from "react-router-dom";
import { message_types } from '../state/constants';

export default () => {

  const [appState, doAction] = useContext(CTX);
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname.substr(1);
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  return (
    <Container>
      <div className="interviews-container">
        {appState.language === 'en' &&
          <p>
            When we begin interviewing the candidates you have chosen,
            you can watch the live stream here, as well as past interviews.
        </p>
        }
        {appState.language === 'ar' &&
          <p>
            يمكنكم متابعة المقابلات مع المرشحين عبر البث الحي بالإضافة إلى مشاهدة المقابلات السابقة
        </p>
        }
      </div>
    </Container>
  )
}
