import React, { useState, useContext, useEffect } from 'react'
import { Container, Button } from 'semantic-ui-react'
import YouTube from 'react-youtube';

import './index.css'
import TextBlock from './textblock';
import TextBlockArabic from './textblock-ar';

import { CTX } from '../state/store';
import { useLocation } from "react-router-dom";
import { message_types } from '../state/constants';

export default () => {

  const largeHeight = '390'
  const largeWidth = '640'
  const smallHeight = '234'
  const smallWidth = '384'

  const [opts, setOpts] = useState({
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0
    }
  });

  const [appState, doAction] = useContext(CTX);
  const [isTextVisible, setIsTextVisible] = useState(false);

  let location = useLocation();

  useEffect(() => {
    const page = location.pathname.substr(1);
    doAction({ type: message_types.SET_SELECT_PAGE, payload: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  function sizeChanged() {

    if (window.innerWidth < 768) {
      setOpts({
        height: smallHeight,
        width: smallWidth,
        playerVars: {
          autoplay: 0
        }
      })
    } else {
      setOpts({
        height: largeHeight,
        width: largeWidth,
        playerVars: {
          autoplay: 0
        }
      })
    }

  }

  useState(() => {
    sizeChanged();
    window.addEventListener("resize", sizeChanged);

    return () => {
      window.removeEventListener("resize");
    }
  }, []);


  return (
    <Container>
      <div className="home-container">
        <YouTube
          videoId="eIDKt94k0gs"
          opts={opts}
        />


        {appState.language === "en" && <Subtitle />}
        {appState.language === "ar" && <SubtitleArabic />}

        {appState.language === "en" &&
          <Button basic color='black' content={isTextVisible ? 'Hide' : 'Find out more'} icon={isTextVisible ? 'up arrow' : 'right arrow'} labelPosition='right'
            onClick={() => setIsTextVisible(!isTextVisible)} />
        }
        {appState.language === "ar" && <Button basic color='black' content={isTextVisible ? 'إخفاء' : 'اكتشف المزيد'} icon={isTextVisible ? 'up arrow' : 'right arrow'} labelPosition='right'
          onClick={() => setIsTextVisible(!isTextVisible)} />}


        {isTextVisible && appState.language === "en" && <TextBlock />}
        {isTextVisible && appState.language === "ar" && <TextBlockArabic />}
      </div>
    </Container>
  )
}

const Subtitle = () => {
  return (
    <p className="subtitle"><b>The Initiative</b> is a platform created by a global network of
    Lebanese volunteers with one goal: To help Lebanon identify its
    next phase of representation.</p>
  )
}

const SubtitleArabic = () => {
  return (
    <p className="subtitle"><b>المبادرة</b> هي منصة ابتكرتها شبكة عالمية من المتطوعين اللبنانيين ولها هدف وحيد هو مساعدة لبنان على التعرف على المرحلة التالية لممثليه</p>
  )
}
